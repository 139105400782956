import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';
import { ROLES } from '../enums/roles.enum';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})

export class AppService {

	menuIsOpen = signal<boolean>(false);
	isMobile = signal<boolean>(false);
	isLoading = signal<boolean>(false);
	logoClickCount = signal<number>(0);

	constructor(
		private router: Router,
		private userService: UserService
	) {
		this.windowResized();
		this.watchRouter();
		this.watchLogoClickCount();
	}

	reloadPage() {
		this.router.navigate([this.router.url], {onSameUrlNavigation: 'reload'})
	}

	windowResized() {
		this.isMobile.set(
			window.innerHeight > window.innerWidth
			? window.innerWidth < 1023
			: window.innerHeight < 450
		)
	}

	private watchRouter():void {
		this.router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			tap((e) => {
				document.getElementById('scroll')?.scrollTo({ top: 0 })
				this.logoClickCount.set(0);
			})
		).subscribe()
	}

	private watchLogoClickCount(): void {
		toObservable(this.logoClickCount).subscribe(() => {
			if (this.logoClickCount() === 5
			&& this.userService.currentUser()?.role === ROLES.ADMIN_FEDE
			&& confirm('Vous allez accéder au formulaire d\insertion de données héritage')) {
				this.router.navigate(['dashboard','insert-data']);
			}
		})
	}
}
