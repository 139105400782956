<section *ngIf="event">
	<header>
		<h1>{{event.activityTitle}}</h1>
		<h6>{{event.companyName}}</h6>
		<h4><a [href]="publicLink" target="_blank">Lien public</a></h4>
	</header>

	<div id="calendar">
		<app-slot-booking-calendar
			mode="PRIVATE"
			[event]="event"
			(select)="askRemoval($event)"
		></app-slot-booking-calendar>
	</div>
</section>
