import { HttpClient } from "@angular/common/http";
import { computed, Injectable, signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import moment from 'moment';
import { Observable, combineLatest, filter, firstValueFrom, switchMap, tap } from "rxjs";
import { CONSTANTS } from "src/constants";
import { environment } from "src/environments/environment";
import { ActivityDto } from "../dtos/activity/activity.dto";
import { ThemeDto } from "../dtos/activity/theme.dto";
import { BookingEventModel } from "../dtos/booking/booking-event.dto";
import { BookingMinimalDto } from "../dtos/booking/booking-minimal.dto";
import { BookingDto } from "../dtos/booking/booking.dto";
import { CreateBookingDto } from "../dtos/booking/create-booking.dto";
import { CreateLegacyProposalDto } from "../dtos/legacy-proposal/legacy-proposal.dto";
import { PageResponse } from '../dtos/page-response.dto';
import { UserDto } from "../dtos/user/user.dto";
import { BOOKING_STATUS, BOOKING_STATUS_OBJ } from "../enums/booking-status.enum";
import { ROLES } from "../enums/roles.enum";
import { BookingRequestParams } from "../models/request.params";
import { ActivityService } from "./activity.service";
import { HelperService } from './helper.service';
import { UserService } from "./user.service";



@Injectable({
	providedIn: 'root',
})

export class BookingService {

	themes = toSignal(
		combineLatest([
			toObservable(this.userService.currentRegion),
			toObservable(this.userService.currentUser)
		])
			.pipe(
				filter(([currentRegion, currentUser]) => {
					return !!currentUser;
				}),
				switchMap(([currentRegion, currentUser]) => {
					return this.activityService.getThemes(currentRegion?.id).pipe(
						tap(themes => {
							if (environment.fillClientCart) {
								this.fillClientCart(themes)
							}
						})
					)
				})
			)
	)

	nextAvailableDate: Date = new Date();
	booking = signal<BookingEventModel[]>([]);
	message: string = "";
	customerId = signal<number>(0);
	readonly BOOKING_STATUS_OBJ = BOOKING_STATUS_OBJ;

	constructor(
		private activityService: ActivityService,
		private http: HttpClient,
		private helperService: HelperService,
		private router: Router,
		private userService: UserService
	) {

		toObservable(this.userService.currentUser)
			.pipe(filter(user => !!user))
			.subscribe(() => this.setNextAvailableDate())
	}

	/**
	 * FOR DEVELOPMENT PURPOSE
	 **/
	async fillClientCart(themes: ThemeDto[]) {
		this.booking.set([]);
		for (var i = 0; i < 3; i++) {
			const randomThemeId = Math.floor(Math.random() * 3);
			const randomActivityId = Math.floor(Math.random() * 6);
			const activityId = themes[randomThemeId].activities[randomActivityId].id;
			const activity = await firstValueFrom(this.activityService.getActivityById(activityId));

			this.booking().push({
				date: this.nextAvailableDate,
				hour: this.nextAvailableDate,
				activity,
				offer: activity.offers[0],
				salle: ""
			})
		}
	}

	activityIsSelected(activity: ActivityDto): boolean {
		return !!this.booking().find(e => e.activity.id === activity.id);
	}

	refreshThemes(): void {
		const currentRegionBck = this.userService.currentRegion();
		this.userService.currentRegion.set(undefined);
		this.userService.currentRegion.set(currentRegionBck);
	}

	bookingZoneIsAllowed(booking: BookingDto): boolean {
		return this.userService.currentUser().zones.map(z => z.id).includes(booking.user.department.zoneId)
	}

	getMaxDiscountPercentage(proposalAmount: number): number {
		return this.userService.currentUser().role === ROLES.ADMIN_FEDE
			? 100
			: proposalAmount > 1000
				? 20
				: 5
	}

	async submitDemand() {
		const warning = CONSTANTS.YOU_SURE + "\n" +
					"Votre demande contient des prestations à éxécuter dans moins de "+CONSTANTS.BOOKING_MIN_DELAY +"jours.\n"+
					"Veuillez vous assurer que la logistique soit en capacité d'honorer la prestation.";

		const hasNearFutureEvents = this.booking().filter(e => moment(e.date).isBefore(moment(new Date()).add(CONSTANTS.BOOKING_MIN_DELAY))).length > 0;

		if (this.userService.currentUser().role === ROLES.CLIENT || !hasNearFutureEvents || confirm(warning)) {
			await firstValueFrom(this.http.post<any>(`${environment.urlApi}/booking`, this.getCreateBookingDto()))
			this.booking.set([]);
			this.initMessage(this.userService.currentUser());
			this.router.navigateByUrl('/dashboard/nouvelle-demande/succes');
		}
	}

	getBookings(params: BookingRequestParams): Observable<PageResponse<BookingMinimalDto>> {
		return this.http.get<PageResponse<BookingMinimalDto>>(
			`${environment.urlApi}/booking/by-filters`,
			{ params: this.helperService.getObjectAsHttpParams(params) }
		)
	}

	getBooking(bookingId: number): Observable<BookingDto> {
		return this.http.get<BookingDto>(`${environment.urlApi}/booking/${bookingId}`)
	}

	initMessage(user: UserDto): void {
		this.message = "Bonjour,\n" +
			"Je souhaiterais que la prestation se tienne à l'adresse suivante:\n" +
			user.address + "\n" +
			user.zipCode + " " + user.city + "\n\n" +
			"N'hésitez pas à me recontacter au " + this.helperService.formatPhoneNumber(user.phone) + "\n" +
			"Ou par email à " + user.email + "\n\n" +
			"Merci,\n" +
			user.firstName + " " + user.lastName;
	}

	reset(bookingId: number) {
		return this.http.post(`${environment.urlApi}/booking/${bookingId}/reset`, null);
	}

	updateBookingStatus(newStatus: BOOKING_STATUS, bookingId: number) {
		return this.http.put(`${environment.urlApi}/booking/${bookingId}/update-status`, {
			status: newStatus,
			bookingId
		})
	}

	createLegacyProposal(dto: CreateLegacyProposalDto) {
		return this.http.post(`${environment.urlApi}/legacy`, dto);
	}

	isDevisValide(booking: BookingDto): boolean {
		return BOOKING_STATUS_OBJ[booking.status].order >= BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_VALIDE].order;
	}

	isDevisEnvoye(booking: BookingDto): boolean {
		return BOOKING_STATUS_OBJ[booking.status].order >= BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_ENVOYE].order;
	}

	isDevisSigne(booking: BookingDto): boolean {
		return BOOKING_STATUS_OBJ[booking.status].order >= BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_SIGNE].order;
	}

	isBookingLocked(booking: BookingDto) {
		return !this.bookingZoneIsAllowed(booking)
			|| this.isDevisSigne(booking)
			|| booking.status === BOOKING_STATUS.REFUSEE
			|| (this.isDevisValide(booking) && this.userService.currentUser().role === ROLES.EMPLOYE_FEDE);
	}

	whyBookingIsLocked(booking: BookingDto) {
		return !this.bookingZoneIsAllowed(booking)
			? 'Cette affaire est hors de votre périmètre'
			: this.isDevisSigne(booking)
				? 'Le devis est accepté'
				: booking.status === BOOKING_STATUS.REFUSEE
					? 'La demande est refusée'
					: (this.isDevisValide(booking) && this.userService.currentUser().role === ROLES.EMPLOYE_FEDE)
						? 'Le devis est validé'
						: null
	}

	private getCreateBookingDto(): CreateBookingDto {
		return {
			message: this.message,
			userId: this.customerId(),
			createProvisionDtos: this.booking().map(event => {
				return {
					activityId: event.activity.id,
					offerId: event.offer.id,
					date: moment(event.date).set('hours', event.hour.getHours()).set('minutes', event.hour.getMinutes()).utc(true).toDate()
				}
			})
		};
	}

	private setNextAvailableDate(): void {
		this.nextAvailableDate = this.changeDate(
			new Date(),
			'FORWARD',
			this.userService.currentUser().role === ROLES.CLIENT ? CONSTANTS.BOOKING_MIN_DELAY : 0
		);
		this.nextAvailableDate.setHours(CONSTANTS.DEFAULT_SESSION_HOUR);
		this.nextAvailableDate.setMinutes(0);
		this.nextAvailableDate.setSeconds(0);
		this.nextAvailableDate.setMilliseconds(0)
	}

	private changeDate(date: Date, direction: 'FORWARD' | 'BACKWARD', nbDays: number): Date {
		const dateAsMoment = moment(date)
		dateAsMoment.add(direction === 'FORWARD' ? nbDays : -nbDays, 'days');

		/** SKIP WEEKENDS */
		if (dateAsMoment.isoWeekday() > 5) {				// 1= Monday, 3= Wednesday, 6= Saturday...
			if (direction === 'FORWARD') {
				dateAsMoment.add(1, 'weeks').isoWeekday(1); // Setting next Monday
			} else {
				dateAsMoment.isoWeekday(5); 				// Setting next Friday
			}
		}
		return dateAsMoment.toDate();
	}
}
