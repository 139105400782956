import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import moment from 'moment';
import { debounceTime, filter, firstValueFrom, Subscription, tap } from 'rxjs';
import { weekendsDatesFilter } from 'src/app/modules/shared/adapters/weekends.filter';
import { BookingDto } from 'src/app/modules/shared/dtos/booking/booking.dto';
import { CreateProvisionsDto } from 'src/app/modules/shared/dtos/booking/create-booking.dto';
import { CommercialProposalDto } from 'src/app/modules/shared/dtos/commercial-proposal/commercial-proposal.dto';
import { ProposalLineDto, ProposalLineUpdateDto } from 'src/app/modules/shared/dtos/commercial-proposal/proposal-line.dto';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { BOOKING_STATUS, BOOKING_STATUS_OBJ } from 'src/app/modules/shared/enums/booking-status.enum';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { CommercialProposalService } from 'src/app/modules/shared/services/commercial-proposal.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { CONSTANTS } from 'src/constants';


@Component({
	selector: 'app-devis-tab.component',
	templateUrl: './devis-tab.component.html',
	styleUrls: ['./devis-tab.component.scss'],
	standalone: false
})
export class DevisTabComponent implements OnInit, OnDestroy {

	readonly BOOKING_STATUS = BOOKING_STATUS;
	readonly BOOKING_STATUS_OBJ = BOOKING_STATUS_OBJ;
	readonly Math = Math;
	readonly ROLES = ROLES;

	proposal: CommercialProposalDto;
	booking: BookingDto;
	ACTIVITY_TYPES = ACTIVITY_TYPES;
	weekendsDatesFilter = weekendsDatesFilter;
	form: FormGroup;
	isLocked: boolean;
	recap: ProposalLineDto[][] = [];

	private subscriptions: Subscription[] = [];

	constructor(
		public appService: AppService,
		public authService: AuthService,
		public helper: HelperService,
		public bookingService: BookingService,
		public userService: UserService,
		private activatedRoute: ActivatedRoute,
		private proposalService: CommercialProposalService,
		private formBuilder: FormBuilder,
		private eventService: EventService,
		private router: Router
	) { }


	async ngOnInit(): Promise<void> {
		this.subscriptions.push(
			this.activatedRoute.parent.data.pipe(filter(parentData => !!parentData.booking ))
			.subscribe(parentData => {
				this.proposal = parentData.proposal;
				this.booking = parentData.booking;
				this.isLocked = this.bookingService.isBookingLocked(parentData.booking);
				this.fixDates();
				this.initForm();
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	get lines() {
		return this.form ? this.form.controls["lines"] as FormArray : new FormArray([]);
	}

	getTotalHT(): number {
		let total = 0;
		this.form.get('lines').value.forEach((l, i) => total += this.getLineTotalPrice(i));
		return total + this.getApplicationFees();
	}

	getTotalTTC(): number {
		return this.getTotalHT() + this.getVat();
	}

	getApplicationFees(): number {
		return this.form.get('applyApplicationFees').value
			? this.form.get('applicationFees').value
			: 0;
	}

	getApplicationFeesTTC(): number {
		return this.form.get('applyVAT').value
			? (this.getApplicationFees() * 1.2)
			: this.getApplicationFees()
	}

	getVat(): number {
		return this.form.get('applyVAT').value
			? this.getTotalHT() * (this.proposal.vatPercentage / 100)
			: 0;
	}

	getEndHour(lineIndex: number): Date {
		const line = this.getLineFromLineIndex(lineIndex);
		return moment(line.provision.events[0]?.date).add(line.provision.offer.sessionDuration, 'minutes').toDate();
	}

	getMaxParticipants(lineIndex: number): number {
		const line = this.getLineFromLineIndex(lineIndex);
		return line.provision.offer.maxPoeple;
	}

	getLineFromLineIndex(lineIndex: number): ProposalLineDto {
		const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
		return this.proposal.lines.find(l => l.id === lineForm.get('lineId').value);
	}

	getUnitPrice(lineIndex: number): number {
		const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
		const line = this.getLineFromLineIndex(lineIndex);
		const onDemandPrice = lineForm.get('onDemandPrice').value;
		const result = line.provision.activity.activityType === ACTIVITY_TYPES.AUTRE ? onDemandPrice : line.unitPrice
		return result > 0 ? result : 0;
	}

	getLineTotalPrice(lineIndex: number): number {
		const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
		const unitPrice = this.getUnitPrice(lineIndex);
		const priceVariation = (1 - lineForm.get('priceVariation').value / 100)
		const result = unitPrice * priceVariation
		return result > 0 ? result : 0;
	}

	async pdf() {
		const content = await firstValueFrom(this.proposalService.getPdfProposalById(this.proposal.id));
		importedSaveAs(content.body, this.proposal.reference + '.pdf')
	}

	getGrandTotal(): number {
		return this.getTotalTTC() + this.proposal.applicationFees;
	}

	getRecapLineSum(lines: ProposalLineDto[]): number {
		let result = 0;
		lines.forEach((line, i) => {
			const formLineIndex = this.lines?.getRawValue().findIndex(l => l.lineId === line.id);
			if (formLineIndex > -1) {
				result += this.getLineTotalPrice(formLineIndex);
			}
		})
		return this.form?.get('applyVAT').value ? result * 1.2 : result;
	}

	getRecapLineDiscount(lines: ProposalLineDto[]): number {
		let total = 0;
		lines.forEach((line) => {
			const formLineIndex = this.proposal.lines.findIndex(l => l.id === line.id);
			total += parseInt(this.lines.at(formLineIndex).getRawValue().priceVariation, 10);
		})
		return total / lines.length;
	}

	addExtraParticipant(lineIndex: number, amount: number) {
		const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
		let currentValue = lineForm.get('extraParticipants').value;
		const newValue = currentValue += amount;
		if (newValue >= 0) {
			lineForm.get('extraParticipants').patchValue(newValue)
		}
	}

	async copyLine(lineIndex: number) {
		if (confirm(CONSTANTS.YOU_SURE)) {
			const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
			const dto: CreateProvisionsDto = {
				activityId: lineForm.get('activityId').value,
				date: lineForm.get('date').value,
				offerId: lineForm.get('offerId').value,
				bookingId: this.proposal.bookingId
			}
			await firstValueFrom(this.eventService.addEventToBooking(dto));
			this.refreshScreen();
		}
	}

	async deleteLine(lineIndex) {
		if (confirm(CONSTANTS.YOU_SURE + '\nLes éventuelles réservations liées à cette activité seront supprimées.\nCette action est irréversible.')) {
			const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
			await firstValueFrom(this.proposalService.removeProvision(this.proposal.id, lineForm.get('provisionId').value));
			this.refreshScreen();
		}
	}

	async setStatus(newStatus: BOOKING_STATUS) {
		await firstValueFrom(
			this.bookingService.updateBookingStatus(
				newStatus,
				this.booking.id
			)
		)
		this.router.navigate([])
	}

	/***
	 * PRIVATE
	 */

	private fixDates(): void {
		this.proposal.lines = this.proposal.lines.map(line => {

			return {
				...line,
				date: moment(line.provision.events[0]?.date).toDate()
			}
		})
	}

	private initForm(): void {
		this.form = this.formBuilder.group({
			lines: this.formBuilder.array(
				this.proposal.lines.map(line => {
					return this.formBuilder.group({
						lineId: [line.id, [Validators.required]],
						activityName: [line.activityTitle],
						activityType: [line.activityType],
						activityId: [line.provision.activity.id],
						provisionId: [line.provision.id],
						date: [line.provision.events[0]?.date],
						offerId: [{
							value: line.provision.offer.id,
							disabled: line.invoiced || this.bookingService.isBookingLocked(this.booking)
						}, [Validators.required]],
						onDemandPrice: [{
							value: line.unitPrice,
							disabled: line.invoiced || this.bookingService.isBookingLocked(this.booking)
						}, [Validators.required]],
						priceVariation: [{
							value: line.priceVariation,
							disabled: line.invoiced || this.bookingService.isBookingLocked(this.booking)
						}, [Validators.required, Validators.min(0), Validators.max(100)]],
						extraParticipants: [line.extraParticipants, [Validators.min(0), Validators.max(5)]]
					})
				})
			),
			applyApplicationFees: [{
				value: this.proposal.applyApplicationFees,
				disabled: this.isLocked
			}],
			applicationFees: [{
				value: this.proposal.applicationFees,
				disabled: this.isLocked
			}],
			applyVAT: [{
				value: this.proposal.applyVAT,
				disabled: this.isLocked
			}]
		})
		this.watchFormValuesChange()
		// this.setRecap();
	}

	private watchFormValuesChange(): void {
		this.watchFormLineValueChange();
		this.watchApplyApplicationFeesChanges();
		this.watchApplicationFeesChanges();
		this.watchApplyVATChanges();
	}

	private watchFormLineValueChange(): void {
		let previousLineValue;

		for (var i = 0; i < (this.form.get('lines') as FormArray).controls.length; i++) {
			this.subscriptions.push(
				this.form.get('lines')
					.get(i.toString())
					.valueChanges
					.pipe(
						tap((changed) => previousLineValue = this.form.get('lines').value.find(v => v.lineId === changed.lineId)),
						debounceTime(300)
					)
					.subscribe(changed => {
						this.updateLineRemote({
							id: changed.lineId,
							offerId: changed.offerId,
							onDemandPrice: changed.onDemandPrice,
							priceVariation: changed.priceVariation,
							extraParticipants: changed.extraParticipants
						})
					})
			)
		}
	}

	private async watchApplyApplicationFeesChanges() {
		this.subscriptions.push(
			this.form.get('applyApplicationFees').valueChanges.pipe(debounceTime(300)).subscribe(async changed => {
				await firstValueFrom(this.proposalService.togglePaymentFee(
					this.proposal.id,
					this.form.get('applyApplicationFees').value)
				);
			})
		)
	}

	private async watchApplicationFeesChanges() {
		this.subscriptions.push(
			this.form.get('applicationFees').valueChanges.pipe(debounceTime(300)).subscribe(async changed => {
				await firstValueFrom(this.proposalService.updateApplicationFees(
					this.proposal.id,
					this.form.get('applicationFees').value)
				);
			})
		)
	}

	private async watchApplyVATChanges() {
		this.subscriptions.push(
			this.form.get('applyVAT').valueChanges.pipe(debounceTime(300)).subscribe(async changed => {
				await firstValueFrom(this.proposalService.toggleVAT(
					this.proposal.id,
					this.form.get('applyVAT').value
				));
			})
		)
	}

	private async updateLineRemote(dto: ProposalLineUpdateDto) {
		const updatedLine = await firstValueFrom(this.proposalService.updateProposalLine(this.proposal.id, dto));
		const index = this.proposal.lines.findIndex(l => l.id === updatedLine.id);
		this.proposal.lines[index] = updatedLine;
	}

	private refreshScreen() {
		this.router.navigate([]);
	}
}
