<section class="container is-max-desktop" *ngIf="event">

	<header style="margin: 10% 0; text-align: center;">
		<h1>{{event.companyName}} vous invite à une séance de {{event.activityTitle.toLowerCase()}}</h1>
		<h4>Veuillez réserver votre créneau</h4>
	</header>


	<app-slot-booking-calendar
		[event]="event"
		mode="PUBLIC"
		(select)="onEventClick($event)"
	></app-slot-booking-calendar>
</section>
