
<menu>
	<a (click)="goStats()" *ngIf="userService.currentUser()?.role != ROLES.CLIENT" [ngClass]="{'active' : router.url.includes('statistiques')}">
		<mat-icon class="is-hidden-desktop">dashboard</mat-icon>
		<span>Tableau de bord</span>
	</a>

	<a (click)="appService.menuIsOpen.set(false)" routerLink="/dashboard/catalogue" routerLinkActive="active">
		<mat-icon class="is-hidden-desktop">emoji_events</mat-icon>
		<span>Catalogue des offres</span>
	</a>

	<a *ngIf="userService.currentUser().role !== ROLES.CLIENT" (click)="appService.menuIsOpen.set(false)" routerLink="/dashboard/calendrier" routerLinkActive="active">
		<mat-icon class="is-hidden-desktop">calendar_today</mat-icon>
		<span>Calendrier</span>
	</a>

	<a (click)="appService.menuIsOpen.set(false)" (click)="goDemandes()" [ngClass]="{'active' : router.url.includes('demandes')}">
		<mat-icon class="is-hidden-desktop">hourglass_empty</mat-icon>
		<span *ngIf="userService.currentUser().role !== ROLES.CLIENT">Liste des affaires</span>
		<span *ngIf="userService.currentUser().role === ROLES.CLIENT">Mes demandes de réservations</span>
	</a>

	<a *ngIf="userService.currentUser().role !== ROLES.CLIENT" (click)="appService.menuIsOpen.set(false)" routerLink="/dashboard/factures" routerLinkActive="active">
		<mat-icon class="is-hidden-desktop">attach_money</mat-icon>
		<span>Factures</span>
	</a>

	<a (click)="appService.menuIsOpen.set(false)" routerLink="/dashboard/utilisateurs" routerLinkActive="active" *ngIf="userService.currentUser()?.role != ROLES.CLIENT">
		<mat-icon class="is-hidden-desktop">dashboard</mat-icon>
		<span>Utilisateurs</span>
	</a>

	<a (click)="appService.menuIsOpen.set(false)"
		*ngIf="userService.currentUser()"
		routerLink="/dashboard/nouvelle-demande"
		routerLinkActive="active"
		matBadgeSize="small"
	>
		<div class="badge">
			<div>{{bookingService.booking().length}}</div>
		</div>
		<mat-icon class="is-hidden-desktop">add_circle</mat-icon>
		<span>Nouvelle {{ userService.currentUser().role === ROLES.CLIENT ? 'réservation' : 'affaire' }}</span>
	</a>

	<a (click)="appService.menuIsOpen.set(false)" routerLink="/dashboard/compte" routerLinkActive="active">
		<mat-icon class="is-hidden-desktop">person</mat-icon>
		<span>Mon compte</span>
	</a>

	<a (click)="logout()">
		<mat-icon class="is-hidden-desktop" style="color: grey">exit_to_app</mat-icon>
		<span>Déconnexion</span>
	</a>

	<!-- <mat-form-field subscriptSizing="dynamic" *ngIf="userService.currentUser().regionalCommittees.length > 1">
		<mat-select placeholder="Toutes les régions" [ngModel]="userService.currentRegion()" (ngModelChange)="userService.currentRegion.set($event)">
			<mat-option [value]="null" *ngIf="userService.currentUser().role === ROLES.ADMIN_FEDE">Toutes les régions</mat-option>
			<mat-option [value]="region" *ngFor="let region of userService.currentUser().regionalCommittees">{{region.title}}</mat-option>
		</mat-select>
	</mat-form-field> -->
</menu>
