import { CommercialProposalDto } from './../../../../shared/dtos/commercial-proposal/commercial-proposal.dto';
import { Dialog } from '@angular/cdk/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subscription, combineLatest, filter, firstValueFrom } from 'rxjs';
import { weekendsDatesFilter } from 'src/app/modules/shared/adapters/weekends.filter';
import { ActivityDto } from 'src/app/modules/shared/dtos/activity/activity.dto';
import { BookingDto } from 'src/app/modules/shared/dtos/booking/booking.dto';
import { EventMinimalDto, ProvisionDto } from 'src/app/modules/shared/dtos/event/event.dto';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { BOOKING_STATUS_OBJ } from 'src/app/modules/shared/enums/booking-status.enum';
import { ActivityService } from 'src/app/modules/shared/services/activity.service';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { CommercialProposalService } from 'src/app/modules/shared/services/commercial-proposal.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { CONSTANTS } from 'src/constants';
import { BookingLinkModal } from './booking-link-modal/booking-link.modal';
import { da } from 'date-fns/locale';

@Component({
    selector: 'app-prestations-tab',
    templateUrl: './prestations-tab.component.html',
    styleUrl: './prestations-tab.component.scss',
    standalone: false
})

export class PrestationsTabComponent implements OnInit, OnDestroy {

	readonly weekendsDatesFilter = weekendsDatesFilter;
	readonly ACTIVITY_TYPES = ACTIVITY_TYPES;
	readonly BOOKING_STATUS_OBJ = BOOKING_STATUS_OBJ;
	readonly theme$ = toObservable(this.bookingService.themes)

	formOpen = false;
	form: FormGroup;
	selectedActivity: ActivityDto;
	pokemonControl = new FormControl();
	booking: BookingDto;
	proposal: CommercialProposalDto;
	activityOptions = [];
	isLocked = false;


	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService,
		public bookingService: BookingService,
		private activatedRoute: ActivatedRoute,
		private activityService: ActivityService,
		private formBuilder: FormBuilder,
		private router: Router,
		private eventService: EventService,
		private dialog: Dialog,
		private proposalService: CommercialProposalService
	) {}

	ngOnInit() {
		this.subscriptions.push(
			this.activatedRoute.parent.data.subscribe(parentData => {
				this.booking = parentData.booking;
				this.proposal = parentData.proposal;
				this.isLocked = this.bookingService.isBookingLocked(this.booking)
				this.initForm();
				this.initActivityOptions();
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	openBookingLinkModal(provision: ProvisionDto): void {
		this.dialog.open(BookingLinkModal, { data: { event: provision.events[0] }})
	}

	showBookingButton(event: ProvisionDto): boolean {
		return this.bookingService.isDevisEnvoye(this.booking) && event.activity.activityType === ACTIVITY_TYPES.INDIVIDUELLE
	}

	getEndDate(event: EventMinimalDto) {
		return moment(event.date).add(event.eventDuration, 'minutes').toDate();
	}

	async getActivityOffers() {
		this.selectedActivity = await (await firstValueFrom(this.activityService.getActivityById(this.form.get("activityId").value)))
		this.form.get('offerId').patchValue(this.selectedActivity.offers[0].id);
	}

	async addEvent() {
		const startDate: Date = this.form.get('date').value;
		const startHour: Date = new Date(this.form.get('hour').value);

		await firstValueFrom(this.eventService.addEventToBooking({
			...this.form.getRawValue(),
			date: moment(startDate).set('hours', startHour.getHours()).set('minutes', startHour.getMinutes()).utc(true).toDate()
		}));

		this.refreshScreen();
		this.formOpen = false;
	}

	async deleteProvision(provisionId: number) {
		if (confirm(CONSTANTS.YOU_SURE + '\nLes éventuelles réservations liées à cette activité seront supprimées.\nCette action est irréversible.')) {
			await firstValueFrom(this.proposalService.removeProvision(this.proposal.id, provisionId));
			this.refreshScreen();
		}
	}

	private refreshScreen(): void {
		this.router.navigateByUrl("dashboard/demandes/"+this.booking.id+'/prestations');
	}

	private initForm(): void {
		this.form = this.formBuilder.group({
			bookingId: [this.booking.id, [Validators.required]],
			activityId: [null, [Validators.required]],
			offerId: [null, [Validators.required]],
			date: [this.booking.provisions[0]?.events[0]?.date, [Validators.required]],
			hour: [this.booking.provisions[0]?.events[0]?.date, [Validators.required]]
		})
	}

	private initActivityOptions(): void {
		this.subscriptions.push(
			this.theme$
			.pipe(filter(themes => !!themes))
			.subscribe((themes) => {
				const activities = themes.map(t => t.activities).flat();
				this.activityOptions = this.helper.orderByString(activities, 'title');
				this.form.get('activityId').patchValue(this.activityOptions[0].id);
				this.getActivityOffers();
			})
		)
	}
}
