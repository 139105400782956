import {Component, Input, OnInit} from '@angular/core';
import {LinkFieldType} from '../../models/FieldConfig';

@Component({
    selector: 'app-link-field',
    templateUrl: './link-field.component.html',
    styleUrls: ['./link-field.component.css'],
    standalone: false
})
export class LinkFieldComponent implements OnInit {

    @Input() value;
    @Input() options: LinkFieldType;

    urlValue: string;

    constructor() {
    }

    ngOnInit() {
        this.urlValue = this.options.url.replace('$value', this.value);
    }

}
