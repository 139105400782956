<section>
	<div class="sub-header">
		<h1>Organiser la prestation</h1>
		<div class="btn-group">
			<button class="button is-primary" (click)="setView(CalendarView.Month)"
			[class.is-inverted]="view !== CalendarView.Month">
			Mois
		</button>
		<button class="button is-primary" (click)="setView(CalendarView.Week)"
			[class.is-inverted]="view !== CalendarView.Week">
			Semaine
		</button>
		<button class="button is-primary" (click)="setView(CalendarView.Day)"
			[class.is-inverted]="view !== CalendarView.Day">
			Jour
		</button>
		</div>
	</div>

	<div class="btn-group">
		<div class="button" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="navigateCalendar()">
			<mat-icon>chevron_left</mat-icon>
			Précédent
		</div>

		<button
			class="button is-primary is-inverted"
			(click)="resetCompletely()"
			[disabled]="bookingService.isBookingLocked(booking)"
			[tooltipStr]="bookingService.whyBookingIsLocked(booking)"
			>
			Réinitialiser selon demande du client
		</button>

		<button class="button" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="navigateCalendar()">
			Suivant
			<mat-icon>chevron_right</mat-icon>
		</button>
	</div>


	<ng-template
		#customHourSegment
		let-segment="segment"
		let-locale="locale"
		let-segmentHeight="segmentHeight"
		let-isTimeLabel="isTimeLabel"
		let-daysInWeek="daysInWeek"
		>
		<div [attr.aria-hidden]="
			{}
				| calendarA11y
				: (daysInWeek === 1
					? 'hideDayHourSegment'
					: 'hideWeekHourSegment')
			" class="cal-hour-segment"
			[style.height.px]="segmentHeight"
			[class.cal-hour-start]="segment.isStart"
			[class.cal-after-hour-start]="!segment.isStart"
			[attr.data-date]="segment.date"
			>
			<div class="cal-time" *ngIf="isTimeLabel">
				{{
					segment.displayDate
					| calendarDate
					: (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour')
					: locale
				}}
			</div>
		</div>
	</ng-template>


	<div id="calendar" [ngSwitch]="view">
		<!-- PAR MOIS -->
		<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" #monthComponent
			[viewDate]="viewDate"
			[locale]="CONSTANTS.CALENDAR_LOCALE"
			[weekStartsOn]="CONSTANTS.CALENDAR_WEEK_STARTS_ON"
			[events]="events"
			[refresh]="refresh"
			[activeDayIsOpen]="activeDayIsOpen"
			(eventTimesChanged)="eventTimesChanged($event)"
			(beforeViewRender)="setPeriod($event)"
			(eventClicked)="eventClicked($event)">
		</mwl-calendar-month-view>

		<!-- PAR SEMAINES -->
		<mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" #weekComponent
			[viewDate]="viewDate"
			[locale]="CONSTANTS.CALENDAR_LOCALE"
			[weekStartsOn]="CONSTANTS.CALENDAR_WEEK_STARTS_ON"
			[dayStartHour]="CONSTANTS.CALENDAR_START_HOUR"
			[dayEndHour]="CONSTANTS.CALENDAR_END_HOUR"
			[hourSegments]="CONSTANTS.CALENDAR_HOUR_SEGMENTS"
			[hourSegmentHeight]="CONSTANTS.CALENDAR_SEGMENT_HEIGHT"
			[events]="events"
			[refresh]="refresh"
			[hourSegmentTemplate]="customHourSegment"
			[excludeDays]="[6,0]"
			(eventTimesChanged)="eventTimesChanged($event)"
			(beforeViewRender)="setPeriod($event)"
			(eventClicked)="eventClicked($event)"
			>
		</mwl-calendar-week-view>

		<!-- PAR JOUR -->
		<ng-container *ngSwitchCase="CalendarView.Day">
			<h4>{{helper.fullDateFr(viewDate)}}</h4>
			<mwl-calendar-day-view  #dayComponent
				[viewDate]="viewDate"
				[locale]="CONSTANTS.CALENDAR_LOCALE"
				[dayStartHour]="CONSTANTS.CALENDAR_START_HOUR"
				[hourSegments]="CONSTANTS.CALENDAR_HOUR_SEGMENTS"
				[hourSegmentHeight]="CONSTANTS.CALENDAR_SEGMENT_HEIGHT"
				[events]="events"
				[refresh]="refresh"
				[hourSegmentTemplate]="customHourSegment"
				(eventTimesChanged)="eventTimesChanged($event)"
				(beforeViewRender)="setPeriod($event)"
				(eventClicked)="eventClicked($event)">
			</mwl-calendar-day-view>
		</ng-container>
	</div>


	<div id="legend">
		<div class="item">
			<div class="bubble white"></div>
			<p>Prestations liées à la demande actuelle</p>
		</div>

		<div class="item">
			<div class="bubble grey"></div>
			<p>Autres prestations sur la zone du client</p>
		</div>
	</div>
</section>
