import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppDateAdapter } from '../adapters/app-date.adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTimepickerModule } from '@angular/material/timepicker';

@NgModule({
	imports: [
		MatButtonModule,
		MatCardModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatPaginatorModule,
		MatTableModule,
		MatGridListModule,
		MatToolbarModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatCheckboxModule,
		MatTimepickerModule
	],
	exports: [
		MatButtonModule,
		MatCardModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatPaginatorModule,
		MatTableModule,
		MatGridListModule,
		MatToolbarModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatCheckboxModule,
		MatTimepickerModule
	],
	providers: [
		{ provide: DateAdapter, useClass: AppDateAdapter }
	]
})
export class MaterialModule { }
