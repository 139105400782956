import { Component, Input, OnInit } from '@angular/core';
import { CheckboxFieldType } from '../../models/FieldConfig';


@Component({
    selector: 'app-checkbox-field',
    templateUrl: './checkbox-field.component.html',
    styleUrls: ['./checkbox-field.component.css'],
    standalone: false
})

export class CheckboxFieldComponent implements OnInit {

    @Input() value;
    @Input() options: CheckboxFieldType;
    @Input() objectValue: any;
    @Input() param: any;

    constructor() { }

    ngOnInit() {
	}

    change() {
        this.options.method(this.param);
    }
}
