import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-tile',
    templateUrl: './tile.component.html',
    styleUrls: ['./tile.component.scss'],
    standalone: false
})
export class TileComponent implements OnInit {

	@Input('imageUrl') imageUrl: string;
	@Input('title') title: string;
	@Input('animate') animate: boolean;
	@Input('aspectRatio') aspectRatio: string;
	@Input('clickable') clickable: boolean;
	@Input('greyFilter') greyFilter: boolean;
	@Input('smallText') smallText: boolean;

	constructor() { }

	ngOnInit(): void {
	}

}
