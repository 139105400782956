import { Component, Input, OnInit } from '@angular/core';
import { RadioFieldType } from './../../models/FieldConfig';


@Component({
    selector: 'app-radio-field',
    templateUrl: './radio-field.component.html',
    styleUrls: ['./radio-field.component.css'],
    standalone: false
})

export class RadioFieldComponent implements OnInit {

    @Input() value;
    @Input() options: RadioFieldType;
    @Input() objectValue: any;
    @Input() param: any;

    constructor() { }

    ngOnInit() {
	}
 }
