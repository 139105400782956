import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CommuneDto } from 'src/app/modules/shared/dtos/commune.dto';
import { RegionalCommitteeBaseDto } from 'src/app/modules/shared/dtos/regional-comittee.dto';
import { CreateClientDto } from 'src/app/modules/shared/dtos/user/create-user.dto';
import { UpdateMyInformationsDto } from 'src/app/modules/shared/dtos/user/update-user.dto';
import { UserDto } from 'src/app/modules/shared/dtos/user/user.dto';
import { JOBS } from 'src/app/modules/shared/models/jobs.list';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { GeoService } from 'src/app/modules/shared/services/geo.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { JmaService } from 'src/app/modules/shared/services/jma.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { accountDoesntExistsValidator } from 'src/app/modules/shared/validators/account-doesnt-exists.validator';
import { matchValidator } from 'src/app/modules/shared/validators/passwords-match.validator';
import { RequestRecoveryComponent } from '../../modals/request-recovery/request-recovery.component';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';
import { DepartmentDto } from 'src/app/modules/shared/dtos/department.dto';
import { CONSTANTS } from 'src/constants';

@Component({
    selector: 'app-inscription',
    templateUrl: './inscription.component.html',
    styleUrls: ['./inscription.component.scss'],
    standalone: false
})


export class InscriptionComponent implements OnInit {

	@ViewChild('cityField', { read: MatAutocompleteTrigger }) cityField: MatAutocompleteTrigger;

	readonly JOBS = JOBS;

	MODE: 'CREATE' | 'UPDATE' = !!this.userService.currentUser() ? 'UPDATE': 'CREATE';

	communes: CommuneDto[] = [];
	form!: FormGroup;
	resForm!: FormGroup;
	comiteRegionals: RegionalCommitteeBaseDto[] = [];
	departments: DepartmentDto[] = [];
	emailExists: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private utilisateurService: UserService,
		private jmaService: JmaService,
		private geoService: GeoService,
		private helper: HelperService,
		private dialog: Dialog,
		private authService: AuthService,
		private userService: UserService,
		private router: Router,
		private notificationService: NotificationService
	) { }

	async ngOnInit() {
		this.comiteRegionals = await firstValueFrom(this.jmaService.getAllComiteRegionals());
		this.initForm();
	}

	forgottenPassword() {
		this.dialog.open(RequestRecoveryComponent, {
			data: { email: this.form.get('email').value }
		})
	}

	codePostalChanged() {
		setTimeout(async () => {
			if (this.form.get('zipCode').valid) {
				const cp = this.form.get('zipCode').value;
				const communes = await firstValueFrom(this.geoService.getCommunesByCodePostal(cp));
				this.communes = this.helper.orderBy(communes, 'nom');

				if (this.communes.length > 0) {
					this.form.get('city').patchValue(this.communes[0].nom);
				}
			}
		}, 0)
	}

	onSubmit(): void {
		if (this.form.valid) {
			this.MODE === 'CREATE' ? this.createUser() : this.updateUser();
		}
	}

	async regionChanged() {
		this.departments = await firstValueFrom(this.jmaService.getDepartments(this.form.get('regionId').value));
		this.form.get('departmentId').patchValue(this.departments[0].id);
	}

	private initForm() {
		const user = this.userService.currentUser();
		this.form = this.formBuilder.group({
			id: [this.MODE === 'CREATE' ? '' : user.id],
			lastName: [this.MODE === 'CREATE' ? '' : user.lastName, [Validators.required]],
			firstName: [this.MODE === 'CREATE' ? '' : user.firstName, [Validators.required]],
			email: [{
				value: this.MODE === 'CREATE' ? '' : user.email,
				disabled: this.MODE === 'UPDATE'
			}, {
				validators: [Validators.required, Validators.email],
				updateOn: 'blur',
			}],
			password: [''],
			passwordTwo: [''],
			address: [this.MODE === 'CREATE' ? '' : user.address, Validators.required],
			addressLineTwo: [this.MODE === 'CREATE' ? '' : user.addressLineTwo],
			zipCode: [this.MODE === 'CREATE' ? '' : user.zipCode, [Validators.required, Validators.pattern('^[0-9]{5}$'), Validators.minLength(5)]],
			city: [this.MODE === 'CREATE' ? '' : user.city, Validators.required],
			phone: [this.MODE === 'CREATE' ? '' : user.phone, [Validators.required, Validators.pattern('^[0-9]{10}$'), Validators.minLength(10)]],
			companyName: [this.MODE === 'CREATE' ? '' : user.companyName, Validators.required],
			function: [this.MODE === 'CREATE' ? '' : user.function, Validators.required],
			regionId: [{
				value: this.MODE === 'CREATE' ? '' : user.regionalCommittees[0].id,
				disabled: this.MODE === 'UPDATE'
			}, Validators.required],
			departmentId: [{
				value: this.MODE === 'CREATE' ? null : user.department.id,
			}, Validators.required],
		})

		if (this.MODE === 'CREATE') {
			this.addPasswordValidators()
			this.form.get('email').addAsyncValidators([accountDoesntExistsValidator(this.utilisateurService)])
		}
	}

	private addPasswordValidators() {
		this.form.get('password').addValidators([Validators.minLength(7), Validators.required, matchValidator('passwordTwo', true)])
		this.form.get('passwordTwo').addValidators([Validators.minLength(7), Validators.required, matchValidator('password')]);
	}


	private createUser() {
		const newUser: CreateClientDto = this.form.getRawValue();
		this.utilisateurService.register(newUser)
			.subscribe(
				response => {
					this.notificationService.showSuccess('Votre compte est créé !', 'Succès !');
					this.authService.authenticateAndGetToken(
						this.form.value.email,
						this.form.value.password
					).subscribe(() => {})
				},
				error => {
					if (error.error.message === " exist.") {
						this.form.controls['email'].setErrors({ 'exists': true });
					} else {
						console.log('Erreur de création de l\'utilisateur.', error);
					}
				}
			);
	}

	private updateUser() {
		const user: UpdateMyInformationsDto = this.form.getRawValue();
		this.utilisateurService.updateMyInformations(user)
			.subscribe(
				(response: UserDto) => {
					this.notificationService.showSuccess('Compte mis à jour', 'Succès !');
					this.authService.setCurrentUser(response);
					this.router.navigate(['dashboard','catalogue'])
				},
				error => console.log('Erreur de mise à jour.', error)
			);
	}
}
