import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, map } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	constructor(
		private notificationService: NotificationService,
		private authService: AuthService
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		return next.handle(req).pipe(
			catchError((response: HttpErrorResponse) => {
				this.notificationService.showError(response.error.message, "Erreur");

				if (response.error.message = "Ce compte n'existe pas.") {
					// this.authService.logout();
				}

				return throwError(response);
			})
		);
	}
}
