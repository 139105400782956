<div id="modal-container">

	<div id="book-form" *ngIf="slot && form">
		<h4>Réserver la séance de <b>{{helper.hourFr(slot.startDate)}}</b> ({{slotMinutes}} minutes)</h4>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div>
				<mat-form-field>
					<mat-label>Nom de famille</mat-label>
					<input matInput formControlName="guestFirstName" placeholder="DUPONT">
				</mat-form-field>
			</div>
			<div>
				<mat-form-field>
					<mat-label>Prénom</mat-label>
					<input matInput formControlName="guestLastName" placeholder="Jean">
				</mat-form-field>
			</div>
			<div style="text-align: center;">
				<button
					class="button is-primary add-to-cart"
					[ngClass]="{'is-fullwidth' : appService.isMobile()}"
					[disabled]="form.invalid"
					type="submit"
				>
					<span class="btn-text">Je réserve !</span>
				</button>
			</div>
		</form>
	</div>
</div>

