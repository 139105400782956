import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { DialogRef } from "@angular/cdk/dialog";
import { ImageCropperModalRequiredArguments } from './models/required-arguments.model';
import { firstValueFrom } from "rxjs";
import { UploadService } from "src/app/modules/shared/services/upload.service";
import { LinkDto } from "src/app/modules/shared/dtos/link.dto";

@Component({
    selector: 'app-image-cropper-modal',
    templateUrl: './image-cropper-modal.component.html',
    styleUrls: ['./image-cropper-modal.component.css'],
    standalone: false
})

export class ImageCropperModal implements OnInit {

	@ViewChild('file') file: HTMLInputElement;
	params: ImageCropperModalRequiredArguments = this.dialogRef.config.data.params as ImageCropperModalRequiredArguments;
	form: UntypedFormGroup;

	croppedImage: any = '';
	imageSourceSize = {
		width: 0,
		height: 0
	};

	constructor(
		public dialogRef: DialogRef,
		private uploadService: UploadService
	) { }

	ngOnInit(): void {
	}

	async imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = await this.getBase64FromFile(event.blob)
	}

	imageLoaded(image: any): void {
		this.imageSourceSize = image.original.size;
	}

	cropperReady(): void {
	}

	loadImageFailed(): void {
	}

	async ok() {
		this.dialogRef.close()
		const dto: LinkDto = await firstValueFrom(
			this.uploadService.uploadImage({
				imageBase64: this.croppedImage,
				fileExtension: 'jpeg'
			})
		);

		if (this.dialogRef.config.data.saveCloseCallback) {
			this.dialogRef.config.data.saveCloseCallback(dto.link);
		}
	}

	getBase64FromFile(file: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '');
				if ((encoded.length % 4) > 0) {
					encoded += '='.repeat(4 - (encoded.length % 4));
				}
				resolve(encoded);
			};
			reader.onerror = error => reject(error);
		});
	}
}
