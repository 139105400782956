import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { RequestRecoveryComponent } from '../../modals/request-recovery/request-recovery.component';

@Component({
    selector: 'app-connexion',
    templateUrl: './connexion.component.html',
    styleUrls: ['./connexion.component.scss'],
    standalone: false
})
export class ConnexionComponent implements OnInit {

	loginForm: FormGroup;

	constructor(
		private fb: FormBuilder,
		private authService: AuthService,
		private dialog: Dialog
	) { }

	ngOnInit(): void {
		this.initForm();
	}

	async submit() {
		if (this.loginForm.valid) {
			await firstValueFrom(
				this.authService.authenticateAndGetToken(
						this.loginForm.value.email,
						this.loginForm.value.password
					)
			)
		}
	}

	forgottenPassword() {
		this.dialog.open(RequestRecoveryComponent, {
			data: { email: this.loginForm.get('email').value }
		})
	}

	private initForm() {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});
	}
}
