import { RadioFieldComponent } from './../fields/radio-field/radio-field.component';
import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { ButtonFieldComponent } from '../fields/button-field/button-field.component';
import { CheckboxFieldComponent } from '../fields/checkbox-field/checkbox-field.component';
import { ImageFieldComponent } from '../fields/image-field/image-field.component';
import { LinkFieldComponent } from '../fields/link-field/link-field.component';
import { LinkFunctionFieldComponent } from '../fields/link-function-field/link-function-field.component';
import { ListFieldComponent } from '../fields/list-field/list-field.component';
import { MenuFieldComponent } from '../fields/menu-field/menu-field.component';
import { TextFieldComponent } from '../fields/text-field/text-field.component';
import { FieldType } from '../models/FieldConfig';


const componentMapper = {
    text: TextFieldComponent,
    button: ButtonFieldComponent,
    link: LinkFieldComponent,
    menu: MenuFieldComponent,
    linkFunction: LinkFunctionFieldComponent,
    list: ListFieldComponent,
    image: ImageFieldComponent,
    checkbox: CheckboxFieldComponent,
	radio: RadioFieldComponent
};

@Directive({
    selector: '[appDynamicField]',
    standalone: false
})
export class DynamicFieldDirective implements OnInit, OnChanges {

    @Input() objectValue: any;
    @Input() value: string;
    @Input() type: FieldType;
    @Input() propertyField?: string;
    @Input() internalAlign?: string;

    componentRef: ComponentRef<any>;

    constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef) {
    }

    ngOnInit(): void {
        const factory = this.resolver.resolveComponentFactory(componentMapper[this.type.value]);
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.value = this.value;
        this.componentRef.instance.options = this.type;
        this.componentRef.instance.objectValue = this.objectValue;

        if (this.type.value === 'linkFunction'
		|| this.type.value === 'button'
		|| this.type.value === "checkbox"
		|| this.type.value === "radio"
		|| this.type.value === "list") {
            this.componentRef.instance.param = this.propertyField;
        }

        if (this.internalAlign === 'center') {
            this.componentRef.instance.options.align = 'center';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.value && this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.value = changes.value.currentValue;
        }
    }

}
