import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CommercialProposalService } from "../../shared/services/commercial-proposal.service";

@Injectable({ providedIn: 'root' })
export class CommercialProposalResolver {

    constructor(private commercialProposalService: CommercialProposalService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
		return this.commercialProposalService.getByBookingId(route.params.id);
    }
}
