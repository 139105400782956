import { ListFieldType } from './../../models/FieldConfig';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TextFieldType} from '../../models/FieldConfig';
import {ThemePalette} from '@angular/material/core';

export interface ChipColor {
    id: number;
    color: ThemePalette;
}

@Component({
    selector: 'app-list-field',
    templateUrl: './list-field.component.html',
    styleUrls: ['./list-field.component.css'],
    standalone: false
})
export class ListFieldComponent implements OnInit {

	@ViewChild('form') form: ElementRef<HTMLFormElement>;
    @Input() value;
    @Input() options: ListFieldType;
	param;
	isEditing = false;

    constructor() {
    }

    ngOnInit() {
    }

	enterEditMode() {
		if (!!this.options.listOptions?.length) {
			this.isEditing = true;
		}
	}

	updateSelection() {
		const checkBoxes = this.form.nativeElement.querySelectorAll('.amianteType:checked');
		let result = [];

		for (var i = 0; i < checkBoxes.length; i++) {
			result.push(checkBoxes.item(i).id)
		}

		this.options.method(this.param, result);
		this.isEditing = false;
	}


}
