import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormGroup, ValidationErrors } from "@angular/forms";
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work

export interface DateOption {
    label: string;
    value: Date;
}

interface Dictionary<T> {
    [Key: string]: T;
}

@Injectable({
    providedIn: 'root'
})

export class HelperService {

    constructor() {}

    MONTHS = {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Mai',
        6: 'Juin',
        7: 'Juillet',
        8: 'Août',
        9: 'Septembre',
        10: 'Octobre',
        11: 'Novembre',
        12: 'Décembre'
    }


    caseUnsensitiveOperation(value) {
        return value.toLowerCase();
    }

    getMonthNameByNumber(month: number): String {
        return this.MONTHS[month];
    }

    compare(propertyName: string, reverseMath: number, operation?: any) {
        const key = operation
			? (x) => operation(x[propertyName])
			: (x) => x[propertyName];

        return (a, b) => {
            const valA: any = key(a);
            const valB: any = key(b);
            return reverseMath * (+(valA > valB) - +(valB > valA));
        };
    }

    orderBy(collection: any[], propertyName: string, reverse?: boolean, operation?: any): any[] {
        const reverseMath: number = !reverse ? 1 : -1;
        const copy = Object.assign([], collection);
        return copy.sort(this.compare(propertyName, reverseMath, operation));
    }

	orderByString(collection: any[], propertyName: string) {
		return collection.sort((a,b) => a[propertyName].localeCompare(b[propertyName]));
	}

    deepCompare(a: any, b: any): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    propertyStringUppercase(collection: any, propertyName: string): any[] {
        return collection.map((a) => {
            a[propertyName] = a[propertyName].toUpperCase();
            return a;
        });
    }

    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.toLocaleLowerCase().slice(1)
    }

	formatPhoneNumber(phone: string): string {
		var ret = [];
		var i;
		var len;

		for(i = 0, len = phone.length; i < len; i += 2) {
		   ret.push(phone.substr(i, 2))
		}

		return ret.join(".")
	}

    getValuesAsPercentage(collection: number[]): number[] {
        const percentValues = [];
        const total = collection.reduce((a, b) => a + b);

        collection.forEach((val) => {
            const roundTenThousand = Math.round((val / total) * 10000);
            percentValues.push(roundTenThousand / 100);
        });

        return percentValues;
    }

    roundTwoDecimals(value: number): number {
        return (Math.round(value * 100)) / 100;
    }

    roundOneDecimal(value: number): number {
        return (Math.round(value * 10)) / 10;
    }

    roundNoDecimal(value: number): number {
        return Math.round(value);
    }

    getSum(mainObject, props: string[]): number {
        let total = 0;
        props.forEach(prop => total += mainObject[prop]);
        return total;
    }

    dictionaryToArray(dictionary: Dictionary<any>, property?: string, reverse?: boolean, operation?): any[] {
        const collection = Object.keys(dictionary).map((key) => {
            return dictionary[key];
        });

        if (property) {
            return this.orderBy(collection, property, reverse, operation);
        } else {
            return collection;
        }
    }

    formatAsName(name: string): string {
        let result = "";
        const names = name.split(" ");
        names.forEach((n, i) => {
            result += n.charAt(0).toUpperCase() + n.slice(1);
            if (i < names.length-1) {
                result += " ";
            }
        })
        return result;
    }

    nike(price: number): string {
        if (price) {
            return price.toString().replace('.', ',');
        }
        return "0,00";
    }

    deepClone(objSource: any): any {
        return JSON.parse(JSON.stringify(objSource));
    }

    dateFr(date): string {
		moment.locale('fr')
        return moment(date).format('DD/MM/YYYY');
    }

	fullDateFr(date): string {
		moment.locale('fr')
		return this.capitalize(moment(date).format('dddd DD/MM/YYYY'))
	}

	monthYearFr(date: Date): string {
		moment.locale('fr');
		return this.capitalize(moment(date).format('MMMM YYYY'))
	}

    hourFr(date): string {
        return moment(date).format('HH[h]mm');
    }

    getEndOfMonthDatesForPeriod(start: Date, end: Date, format?: string): DateOption[] {
        const min = moment(start).endOf('month');
        const iterator = min;
        const max = moment(end);

        const options: DateOption[] = [];
        options.push({
            label: min.locale('fr').format(format ? format : 'DD-MM-YYYY'),
            value: min.toDate()
        });

        while (iterator.isBefore(max, 'month')) {
            iterator.add(1, 'month');

            if (iterator.isBefore(max, 'month')) {
                options.push({
                    label: iterator.endOf('month').locale('fr').format(format ? format : 'DD-MM-YYYY'),
                    value: iterator.endOf('month').toDate()
                });
            } else {
                options.push({
                    label: max.locale('fr').format(format ? format : 'DD-MM-YYYY'),
                    value: max.toDate()
                });
            }
        }

        return options;
    }


    entitiesAreEquivalent(modelEntity: any, entity: any, exclusionsKeys: string[]): boolean {
        let areEqual = true;
        Object.keys(modelEntity).forEach((key) => {
            if (entity[key] !== modelEntity[key] && !exclusionsKeys.find((ex) => ex === key)) {
                areEqual = false;
            }
        });
        return areEqual;
    }


    getObjectAsHttpParams(object: Object): HttpParams {
        let params = new HttpParams();
        for (const property in object) {
            if (object[property] != null && object[property] != "" && object[property] != 0) {
                params = params.append(property, object[property]);
            }
        }
        return params;
    }

    getUrlParamsFromObject(object: Object): string {
        let str = "";
        for (let key in object) {
            if (str != "") {
                str += "&";
            }
            if (!!object[key] && object[key].value != 0) {
                str += key + "=" + encodeURIComponent(object[key]);
            }
        }
        return str;
    }

    getRandomCharDigitsString(length: number): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
       return result;
    }

    getBase64FromFile(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
              encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
          };
          reader.onerror = error => reject(error);
        });
    }

    logFormGroupErrors(form: UntypedFormGroup) {
		console.log('logFormGroupErrors', form)
		Object.keys(form.controls).forEach(key => {
			console.log('kye', key)
			const controlErrors: ValidationErrors = form.get(key).errors;
			console.log('controlErrors', controlErrors)
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}

	getNumber(reference: string): number {
		return parseInt((reference.match(/\d+/g) as RegExpMatchArray)?.join(), 10);
	}
}
