import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	constructor(private authService: AuthService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authToken = this.authService.getToken();

		// Vérifie si le token est expiré
		if (authToken) {
			const authReq = req.clone({
				headers: req.headers.set('Authorization', `Bearer ${authToken}`)
			});
			return next.handle(authReq);
		}

		return next.handle(req);
	}

}
