import { Component, HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from './modules/shared/services/app.service';
import { AuthService } from './modules/shared/services/auth.service';
import { LoaderInterceptor } from './modules/shared/interceptors/loader.interceptor';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.appService.windowResized()
	}

	constructor(
		public appService: AppService,
		private authService: AuthService,
		private router: Router
	) { }


	ngOnInit(): void {
		this.authService.testLogin();
		// this.watchNavigation();
	}

	private watchNavigation() {
		this.router.events.subscribe(e => {
			if (e instanceof NavigationStart) {
				this.appService.isLoading.set(true);
			}
			if (e instanceof NavigationEnd && this.appService.isLoading() === true) {
				setTimeout(() => this.appService.isLoading.set(false), 500)
			}
		})
	}

}
