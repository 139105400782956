import { Component, OnInit } from '@angular/core';
import moment from 'moment';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})


export class FooterComponent {

	readonly YEAR = moment().get('year')

}
