export const CONSTANTS = {
	YOU_SURE: 'Êtes-vous sûr ?',
	DEFAULT_PAGE_LENGTH: 50,
	PAGINATION_OPTIONS: [10, 20, 50, 100],
	BOOKING_MIN_DELAY: 14,
	DEFAULT_SESSION_HOUR: 9,
    CALENDAR_LOCALE: "fr-FR",
    CALENDAR_WEEK_STARTS_ON: 1,
    CALENDAR_START_HOUR: 0,
	CALENDAR_END_HOUR: 24,
    CALENDAR_HOUR_SEGMENTS: 2,
    CALENDAR_SEGMENT_HEIGHT: 24,
    DATE_TRANSACTION_FORMAT: "YYYY-MM-DD",
    HOUR_TRANSACTION_FORMAT: "HH:mm:ss",
    DATE_HOUR_TRANSACTION_FORMAT: "YYYY-MM-DD[T]HH:mm:ss",
	SWITCH_BOOKING: "Ouvrir sur la demande correspondante ?"
}
