
import { CalendarEvent } from "angular-calendar";
import moment from "moment";
import { CONSTANTS } from "src/constants";
import { EventMinimalDto } from "../dtos/event/event.dto";
import { ACTIVITY_TYPES } from '../enums/activity-type.enum';



export class EventBooking {

	id: number;
	eventDuration: number;
	activityTitle: string;
	activityType: ACTIVITY_TYPES;
	companyName: string;
    bookingId: number;
	private _date: Date;

	constructor(dto: EventMinimalDto) {
		this.id = dto.id;
		this.eventDuration = dto.eventDuration;
		this.activityTitle = dto.activityTitle;
		this.activityType = dto.activityType;
		this.companyName = dto.companyName;
		this.bookingId = dto.bookingId;
		this._date = moment(dto.date, CONSTANTS.DATE_HOUR_TRANSACTION_FORMAT).toDate();
	}

	set date(date: Date) {
		this._date = moment(date).toDate();
	}

	get date() {
		return this._date;
	}

	get start() {
		const start = moment(this._date);
		if (start.get('hours') < CONSTANTS.CALENDAR_START_HOUR || start.get('hours') > CONSTANTS.CALENDAR_END_HOUR - 1) {
			start.set('hours', CONSTANTS.CALENDAR_START_HOUR);
		}
		return start.toDate();
	}

	get end() {
		const end = moment(this.start);
		end.add(this.eventDuration, 'minutes');
		return end.toDate();
	}

	getCalendarEvent(bookingId: number): CalendarEvent {
		const isCurrentBooking: boolean = this.bookingId === bookingId;

		return {
			id: this.id,
			start: this.start,
			end: this.end,
			title: this.activityTitle + '<BR/>' + this.companyName,
			color: {
				primary: isCurrentBooking && this.activityType === ACTIVITY_TYPES.INDIVIDUELLE
					? 'red'
					: isCurrentBooking
						? 'blue'
						: '#ccc',
				secondary: isCurrentBooking ? '#FFF' : '#ccc',
				secondaryText: this.companyName
			},
			draggable: isCurrentBooking,
			resizable: {
				beforeStart: this.activityType === ACTIVITY_TYPES.AUTRE,
				afterEnd: this.activityType === ACTIVITY_TYPES.AUTRE
			}
		}
	}

	get calendarEvent(): CalendarEvent {
		return {
			id: this.id,
			start: this.start,
			end: this.end,
			title: this.activityTitle + '<BR/>' + this.companyName,
			color: {
				primary: "#ccc",
				secondary: "#FFF",
				secondaryText: this.companyName
			},
			draggable: false,
			resizable: {
				beforeStart: false,
				afterEnd: false
			}
		}
	}

	get dto(): EventMinimalDto {
		return {
			id: this.id,
			eventDuration: this.eventDuration,
			activityTitle: this.activityTitle,
			activityType: this.activityType,
			companyName: this.companyName,
			bookingId: this.bookingId,
			date: moment(this.date).format(CONSTANTS.DATE_HOUR_TRANSACTION_FORMAT)
		}
	}
}
