import { distinctUntilChanged } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { toObservable } from '@angular/core/rxjs-interop';


@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false
})
export class LoaderComponent implements OnInit {

    // loading: boolean;
    // type: number;
	// isLoading$ =  toObservable(this.appService.isLoading);

    constructor(
		// private appService: AppService,
        // private cdRef: ChangeDetectorRef
	) { }

    ngOnInit(): void {
		// this.isLoading$.pipe(
        //     distinctUntilChanged((prev, next) => prev === next)
        // ).subscribe((v) => {
        //     this.loading = v;
        //     this.cdRef.detectChanges();
        // });
    }
}
