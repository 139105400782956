<section class="container-fluid">
	<form class="header" *ngIf="!!form" [formGroup]="form">
		<h1>Calendrier</h1>

		<div class="btn-group">
			<div class="button" mwlCalendarPreviousView [view]="calendarView" [(viewDate)]="viewDate" (viewDateChange)="resetCalendar()">
				<mat-icon>chevron_left</mat-icon>
				Précédent
			</div>
			<div style="width: 100px;"></div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Zone géographique</mat-label>
					<mat-select formControlName="zoneId" (selectionChange)="loadEvents()">
						<mat-option [value]="0">Toutes les zones</mat-option>
						<mat-option *ngFor="let zone of userService.allZones()" [value]="zone.id">
							{{zone.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div style="width: 100px;"></div>

			<button class="button" mwlCalendarNextView [view]="calendarView" [(viewDate)]="viewDate" (viewDateChange)="resetCalendar()">
				Suivant
				<mat-icon>chevron_right</mat-icon>
			</button>


		</div>

		<div class="btn-group">
			<button class="button is-primary" (click)="setView(CalendarView.Month)"
				[class.is-inverted]="calendarView !== CalendarView.Month">
				Mois
			</button>
			<button class="button is-primary" (click)="setView(CalendarView.Week)"
				[class.is-inverted]="calendarView !== CalendarView.Week">
				Semaine
			</button>
			<button class="button is-primary" (click)="setView(CalendarView.Day)"
				[class.is-inverted]="calendarView !== CalendarView.Day">
				Jour
			</button>
		</div>
	</form>


	<ng-template
		#customHourSegment
		let-segment="segment"
		let-locale="locale"
		let-segmentHeight="segmentHeight"
		let-isTimeLabel="isTimeLabel"
		let-daysInWeek="daysInWeek"
		>
		<div [attr.aria-hidden]="
	          {}
	            | calendarA11y
	              : (daysInWeek === 1
	                  ? 'hideDayHourSegment'
	                  : 'hideWeekHourSegment')
	        " class="cal-hour-segment"
			[style.height.px]="segmentHeight"
			[class.cal-hour-start]="segment.isStart"
			[class.cal-after-hour-start]="!segment.isStart"
			[attr.data-date]="segment.date"
			>
			<div class="cal-time" *ngIf="isTimeLabel">
				{{
				segment.displayDate
				| calendarDate
				: (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour')
				: locale
				}}
			</div>
		</div>
	</ng-template>

	<div [ngSwitch]="calendarView" class="box">
		<!-- PAR MOIS -->
		<ng-container *ngSwitchCase="CalendarView.Month">
			<h4>{{helper.monthYearFr(viewDate)}}</h4>
			<mwl-calendar-month-view  #monthComponent
				[viewDate]="viewDate"
				[locale]="CONSTANTS.CALENDAR_LOCALE"
				[weekStartsOn]="CONSTANTS.CALENDAR_WEEK_STARTS_ON"
				[events]="events"
				[refresh]="refresh"
				[activeDayIsOpen]="activeDayIsOpen"
				(beforeViewRender)="setPeriod($event)"
				(eventClicked)="eventClicked($event)">
			</mwl-calendar-month-view>
		</ng-container>

		<!-- PAR SEMAINES -->
		<mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" #weekComponent
			[viewDate]="viewDate"
			[locale]="CONSTANTS.CALENDAR_LOCALE"
			[weekStartsOn]="CONSTANTS.CALENDAR_WEEK_STARTS_ON"
			[dayStartHour]="CONSTANTS.CALENDAR_START_HOUR"
			[dayEndHour]="CONSTANTS.CALENDAR_END_HOUR"
			[hourSegments]="CONSTANTS.CALENDAR_HOUR_SEGMENTS"
			[hourSegmentHeight]="CONSTANTS.CALENDAR_SEGMENT_HEIGHT"
			[events]="events"
			[refresh]="refresh"
			[hourSegmentTemplate]="customHourSegment"
			[excludeDays]="[6,0]"
			(beforeViewRender)="setPeriod($event)"
			(eventClicked)="eventClicked($event)">
		</mwl-calendar-week-view>

		<!-- PAR JOUR -->
		<ng-container *ngSwitchCase="CalendarView.Day">
			<h4>{{helper.fullDateFr(viewDate)}}</h4>
			<mwl-calendar-day-view  #dayComponent
				[viewDate]="viewDate"
				[locale]="CONSTANTS.CALENDAR_LOCALE"
				[dayStartHour]="CONSTANTS.CALENDAR_START_HOUR"
				[hourSegments]="CONSTANTS.CALENDAR_HOUR_SEGMENTS"
				[hourSegmentHeight]="CONSTANTS.CALENDAR_SEGMENT_HEIGHT"
				[events]="events"
				[refresh]="refresh"
				(beforeViewRender)="setPeriod($event)"
				(eventClicked)="eventClicked($event)"
				>
			</mwl-calendar-day-view>
		</ng-container>
	</div>
</section>
