import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable, map } from "rxjs";
import { CONSTANTS } from "src/constants";
import { environment } from "src/environments/environment";
import { BookSlotDto } from "../dtos/booking/book-slot.dto";
import { CreateProvisionsDto } from "../dtos/booking/create-booking.dto";
import { HelperService } from "./helper.service";
import { EventDto, EventMinimalDto } from "../dtos/event/event.dto";
import { EventBooking } from "../models/event-booking";
import { CalendarData, CalendarDto } from "../dtos/calendar.dto";
import { TemporalVariable } from "../models/temporal-variable";


@Injectable({
	providedIn: 'root',
})

export class EventService {

	constructor(
		private http: HttpClient,
		private helperService: HelperService
	) {}


	getEventsByPeriod(startAt: Date, endAt: Date, zoneId?: number): Observable<CalendarData> {
		const params = this.helperService.getObjectAsHttpParams({
			startAt: moment(startAt).format(CONSTANTS.DATE_TRANSACTION_FORMAT),
			endAt: moment(endAt).format(CONSTANTS.DATE_TRANSACTION_FORMAT),
			zoneId
		});
		return this.http.get<CalendarDto>(`${environment.urlApi}/event/by-period`, { params })
			.pipe(
				map(dto => {
					return {
						events: dto.events.map(dto => new EventBooking(dto)),
						temporalVariables: dto.temporalVariables.map(dto => new TemporalVariable(dto))
					}
				})
			)
	}

	getEvent(eventId: number) {
		return this.http.get<EventDto>(`${environment.urlApi}/event/${eventId}`)
	}

	getPublicEvent(eventId: number) {
		return this.http.get<EventDto>(`${environment.urlApi}/public/event/${eventId}`)
	}

	updateEvent(dto: EventMinimalDto) {
		return this.http.put(`${environment.urlApi}/event/${dto.id}`, dto)
	}

	addEventToBooking(dto: CreateProvisionsDto) {
		return this.http.post(`${environment.urlApi}/booking/${dto.bookingId}/add-event`, dto);
	}

	bookSlot(dto: BookSlotDto) {
		return this.http.put(
			`${environment.urlApi}/public/book-slot/${dto.slotId}`,
			dto
		)
	}

	cancelBooking(slotId: number) {
		return this.http.put(
			`${environment.urlApi}/reservation-slot/cancel-booking/${slotId}`,
			{}
		)
	}

}
