import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { catchError, delay, distinctUntilChanged, map, of, switchMap, tap } from "rxjs";
import { UserService } from "../services/user.service";

export const accountDoesntExistsValidator = (userService: UserService): AsyncValidatorFn => {
	return (control: AbstractControl) => {
		return of(control.value).pipe(
			distinctUntilChanged((prev, next) => prev === next),
			delay(500),
			switchMap(() => userService.emailAlreadyExists(control.value)),
			map(response => !response.exists ? null: { exists: true, message: 'Ce compte éxiste déjà.' }),
			catchError((err) => null)
		)
	}
}
