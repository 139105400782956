import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

@Injectable()

export class AppDateAdapter extends NativeDateAdapter {

	// override

    // override getFirstDayOfWeek(): number {
    //     return 1;
    // }

	// override format(date: Date, displayFormat: any): string {
	// 	console.log('format', date)
    //     if (displayFormat === 'input') {
    //         let day: string = date.getDate().toString();
    //         day = +day < 10 ? '0' + day : day;
    //         let month: string = (date.getMonth() + 1).toString();
    //         month = +month < 10 ? '0' + month : month;
    //         const year = date.getFullYear();
	// 		console.log("!" + "")
    //         return `${day}/${month}/${year}`;
    //     }

    //     return date.toLocaleDateString();
    // }

    override parse(value: any): Date | null {
        if (typeof value === 'string') {
            value = value.split('/');
            return new Date(value[2], value[1] - 1, value[0]);

        } else if (value === 'date') {
            return value;
        }

        return null;
    }
}

