<section class="container-fluid" *ngIf="booking">

	<h1>#{{proposal.reference}}</h1>

	<div class="columns" style="margin-right: 3rem;">
		<!-- LEFT -->
		<div id="left" class="column is-3">
			<h4>Etat de la demande</h4>
			<mat-form-field *ngIf="userService.currentUser().role !== ROLES.CLIENT">
				<mat-select [(ngModel)]="booking.status" (ngModelChange)="updateBookingStatus()">
					<mat-option
						*ngFor="let option of BOOKING_STATUS_OPTIONS"
						[value]="option.value"
						[disabled]="option.disabled || isLocked"
						>{{option.label}}</mat-option>
				  </mat-select>
			</mat-form-field>

			<div id="status-container" *ngIf="userService.currentUser().role === ROLES.CLIENT">
				<div class="status" [ngClass]="booking.status">
					{{BOOKING_STATUS_OBJ[booking.status].label}}
				</div>
			</div>


			<h4>Informations sur la demande</h4>
			<div class="columns">
				<div class="column field"># Bon de commande</div>
				<div class="column value" *ngIf="booking.purchaseOrder">{{booking.purchaseOrder}}</div>
				<div class="column value" *ngIf="!booking.purchaseOrder">
					<span>Aucun</span>
					<mat-icon
						*ngIf="userService.currentUser().role !== ROLES.CLIENT"
						style="margin-left:6px; margin-top: 2px; width: 12px !important; height: 12px !important; font-size: 12px !important"
						tooltipStr="Ajoutez un document de type bon de commande pour remplir ce champ">
						help_outline
					</mat-icon>
				</div>
			</div>
			<div class="columns">
				<div class="column field">Entreprise</div>
				<div class="column value">{{booking.user.companyName}}</div>
			</div>
			<div class="columns">
				<div class="column field">Région</div>
				<div class="column value">{{booking.user.regionalCommittees[0].title}}</div>
			</div>
			<div class="columns">
				<div class="column field">Date d'envoi</div>
				<div class="column value">{{helper.dateFr(booking.date)}}</div>
			</div>
			<div class="columns">
				<div class="column field">Nom</div>
				<div class="column value">{{booking.user.firstName}} {{booking.user.lastName}}</div>
			</div>
			<div class="columns">
				<div class="column field">Adresse email</div>
				<div class="column value">{{booking.user.email}}</div>
			</div>
			<div class="columns">
				<div class="column field">Téléphone</div>
				<div class="column value">{{helper.formatPhoneNumber(booking.user.phone)}}</div>
			</div>
			<div class="columns is-multiline">
				<div class="column is-12 field" style="padding-bottom: 0; margin-bottom: 0;">Message</div>
				<div class="column is-12">
					<pre>{{getMessage()}}</pre>
				</div>
			</div>
		</div>
		<!-- RIGHT -->
		<div id="right" class="column is-9">
			<div id="tabs">
				<div id="line"></div>
				<a *ngFor="let tab of TABS"
					[routerLink]="'/dashboard/demandes/'+booking.id+'/'+tab.route"
					routerLinkActive="active"
				>
					<div>{{tab.label}}</div>
				</a>
			</div>

			<div id="frame">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>

</section>
