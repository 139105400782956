import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { weekendsDatesFilter } from 'src/app/modules/shared/adapters/weekends.filter';
import { ActivityMinimalDto } from 'src/app/modules/shared/dtos/activity/activity.dto';
import { OfferDto } from 'src/app/modules/shared/dtos/offer/offer.dto';
import { UserMinimalDto } from 'src/app/modules/shared/dtos/user/user.minimal.dto';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { ActivityService } from 'src/app/modules/shared/services/activity.service';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { CreateLegacyProposalDto, LegacyProposalLineDto } from '../../../shared/dtos/legacy-proposal/legacy-proposal.dto';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { ClientFilterComponent } from 'src/app/modules/shared/models/client-filter.component';
import { ComparatorService } from 'src/app/modules/shared/services/comparator.service';


@Component({
    selector: 'app-insert-data.component',
    templateUrl: './insert-data.component.html',
    styleUrls: ['./insert-data.component.scss'],
    standalone: false
})

export class InsertDataComponent extends ClientFilterComponent implements OnInit, OnDestroy {

	readonly A_LA_CARTE = 43;

	activites: ActivityMinimalDto[] = [];
	offers: { [key : number]: OfferDto[] } = {};
	ACTIVITY_TYPES = ACTIVITY_TYPES;
	weekendsDatesFilter = weekendsDatesFilter;


	private subscriptions: Subscription[] = [];

	constructor(
		public userService: UserService,
		public appService: AppService,
		public authService: AuthService,
		public helper: HelperService,
		public bookingService: BookingService,
		protected override comparatorService: ComparatorService,
		private formBuilder: FormBuilder,
		private activityService: ActivityService,
		private notificationService: NotificationService
	) { super(comparatorService) }

	get lines() {
		return this.form.controls["lines"] as FormArray;
	}

	async ngOnInit(): Promise<void> {
		this.getAllActivites();
		this.initForm();
		this.loadCustomers();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	async loadActivityOffers(lineIndex: number) {
		const activityId = this.lines.at(lineIndex).get('activiteId').value;
		if (!this.offers[activityId]) {
			this.offers[activityId] = await firstValueFrom(
				this.activityService.getActivityOffers(this.lines.at(lineIndex).get('activiteId').value)
			)

			this.lines.at(lineIndex).get('offreId').patchValue(this.offers[activityId][0].id)
			this.lines.at(lineIndex).get('onDemandPrice').patchValue(this.offers[activityId][0].price)
		}
	}

	zoneChanged() {
		this.form.get('clientId').patchValue(null);
		this.loadCustomers();
	}

	getOffersForIndex(i: number) {
		const activityId = this.lines.at(i)?.get('activiteId').value;
		if (!activityId || !this.offers[activityId]) {
			return [];
		}

		return this.offers[activityId]
	}

	addPrestation(line?: LegacyProposalLineDto): void {
		this.lines.push(
			this.formBuilder.group({
				activiteId: [line?.activiteId, [Validators.required]],
				offreId: [line?.offreId, [Validators.required]],
				dateExecution: [line?.dateExecution ? line?.dateExecution : this.form.get('dateDevis').value, [Validators.required]],
				remise: [line?.remise || 0 , [Validators.min(0)]],
				onDemandPrice: [line?.onDemandPrice]
			})
		)
	}

	offerChanged(index: number) {
		const offers = this.getOffersForIndex(index);
		const offerId = this.lines.at(index)?.get('offreId').value
		const offer = offers.find(o => o.id === offerId);
		this.lines.at(index).get('onDemandPrice').patchValue(offer.price);
	}

	async loadCustomers() {
		this.customers = (
			await firstValueFrom(
				this.userService.getByFilters({
					zoneId: this.form.get('zoneId')?.value,
					role: ROLES.CLIENT,
					limit: 9999
				})
			)).content
			this.filteredCustomers = this.customers;
	}

	getTotalHT(): number {
		let total = 0;
		this.form.get('lines').value.forEach((l, i ) => {
			total += this.getLineTotalPrice(i)
		})
		return total;
	}

	getTotalTTC(): number {
		return this.getTotalHT() + this.getVat();
	}

	getVat(): number {
		if (this.form.get('tva').value) {
			return this.getTotalHT() * 0.2;
		}
		return 0;
	}

	getUnitPrice(lineIndex: number): number {
		const line = this.lines.at(lineIndex);
		const activityOffers = this.offers[line.get('activiteId').value]

		if (!line.get('activiteId').value || !line.get('offreId')?.value || ! activityOffers) {
			return 0;
		}

		const offer = activityOffers.find(o => o.id === line.get('offreId')?.value);
		return offer?.price || 0;
	}

	getLineTotalPrice(lineIndex: number): number {
		const line = this.lines.at(lineIndex);
		const variation = (1 - line.get('remise').value / 100);

		// if (line.get('activiteId').value === this.A_LA_CARTE) {
			return line.get('onDemandPrice').value * variation;
		// }

		// return this.getUnitPrice(lineIndex) * variation;
	}

	double(lineIndex: number) {
		this.addPrestation(this.lines.at(lineIndex).getRawValue());
	}

	async save() {
		const date = this.form.get('dateDevis').value;
		date.setMinutes(date.getMinutes() - date.getTimezoneOffset())

		let dto: CreateLegacyProposalDto = {
			clientId: this.form.get('clientId').value,
			dateDevis: date,
			tva: this.form.get('tva').value,
			lines: this.getLinesDto()
		}

		await firstValueFrom(this.bookingService.createLegacyProposal(dto))
		this.notificationService.showSuccess('La prestation a été enregistrée', 'Succès');
		this.ngOnInit();
	}

	private getLinesDto(): LegacyProposalLineDto[] {
		const rawLines: LegacyProposalLineDto[] = this.lines.getRawValue();
		return rawLines.map(rL => {
			const date = (rL.dateExecution as Date);
			date.setMinutes(date.getMinutes() - date.getTimezoneOffset())

			return {
				...rL,
				dateExecution: date
			}
		})
 	}

	private async getAllActivites() {
		const rawActivites = _.flatten(
			(await firstValueFrom(this.activityService.getThemes())).map(theme => theme.activities)
		)
		this.activites = rawActivites.sort((a, b) => a.title.localeCompare(b.title))
	}

	override initForm(): void {
		this.form = this.formBuilder.group({
			customerSearch: [''],
			zoneId: [0, [Validators.required]],
			clientId: [null, [Validators.required]],
			dateDevis: [null, [Validators.required]],
			tva: [false],
			lines: this.formBuilder.array([])
		})
		this.addPrestation();
		this.watchSearchCustomers();
	}

	override reloadContent() {
		this.loadCustomers();
	}
}
