<div class="container is-fullhd">
	<h1 *ngIf="userService.currentUser().role === ROLES.CLIENT">Nouvelle demande de réservation</h1>
	<h1 *ngIf="userService.currentUser().role !== ROLES.CLIENT">Nouvelle affaire</h1>

	<!-- BUTTONS -->
	<div class="buttons">
		<button
			class="button is-primary"
			routerLink="/dashboard/catalogue">
			<mat-icon>add</mat-icon>
			<span>Ajouter des {{ userService.currentUser().role === ROLES.CLIENT ? 'activités à ma demande' : 'prestations à l\'affaire' }} </span>
		</button>
	</div>

	<p *ngIf="bookingService.booking().length === 0">Vous n'avez sélectionné aucune activité.</p>

	<!-- EVENT LIST -->
	<div class="booking" *ngFor="let bookingEvent of bookingService.booking(); let eventIndex = index;">
		<div class="columns is-vcentered">
			<div class="column is-3">
				<app-tile [animate]="false" [imageUrl]="bookingEvent.activity.image"
					[title]="bookingEvent.activity.title" [smallText]="true" aspectRatio="8/5">
				</app-tile>
			</div>
			<div class="column is-9">
				<!-- LINE 1 -->
				<mat-form-field>
					<mat-label>Format souhaité</mat-label>
					<mat-select required [(ngModel)]="bookingEvent.offer">
						<mat-option *ngFor="let o of bookingEvent.activity.offers" [value]="o">
							<span>{{o.sessionConditions}}</span>
							<span
								*ngIf="bookingEvent.activity.activityType != ACTIVITY_TYPES.AUTRE
									&& bookingEvent.activity.activityType != ACTIVITY_TYPES.FICHE">
								({{Math.round(o.sessionDuration * o.nbEvents / 60).toFixed(2).replace('.', 'h')}})
							</span>
						</mat-option>
					</mat-select>
				</mat-form-field>
				<!-- LINE 2 -->
				<div class="bottom">
					<div class="columns is-vcentered is-gapless">
						<!-- DATE -->
						<div class="column is-4">
							<mat-form-field subscriptSizing="dynamic" *ngIf="bookingEvent.activity.activityType !== ACTIVITY_TYPES.FICHE">
								<mat-label>Date souhaitée <span *ngIf="bookingEvent.offer.nbEvents > 1">de la première séance</span></mat-label>
								<input matInput required
									[(ngModel)]="bookingEvent.date"
									[matDatepicker]="datepicker"
									placeholder="Date de fin"
									[min]="bookingService.nextAvailableDate"
									[matDatepickerFilter]="weekendsDatesFilter"
								>
								<mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
								<mat-datepicker #datepicker ></mat-datepicker>
							</mat-form-field>
							<i></i>
						</div>
						<div class="column is-3">
							<mat-form-field subscriptSizing="dynamic" *ngIf="bookingEvent.activity.activityType !== ACTIVITY_TYPES.FICHE">
								<mat-label>Heure souhaitée</mat-label>
								<input matInput required
									[(ngModel)]="bookingEvent.hour"
									[matTimepicker]="picker"
								>
								<mat-timepicker-toggle matIconSuffix [for]="picker"/>
								<mat-timepicker #picker/>
							</mat-form-field>
						</div>
						<!-- BUTTONS -->
						<div class="column">
							<div class="event-buttons">
								<button class="button is-primary is-inverted"
									[ngClass]="{'is-fullwidth' : appService.isMobile() }"
									(click)="duplicate(bookingEvent)">
									Doubler cette activité
								</button>

								<button class="button is-white" [ngClass]="{'is-fullwidth' : appService.isMobile() }"
									(click)="bookingService.booking().splice(eventIndex, 1)">
									Retirer
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- CUSTOMER -->
	<form id="customer" [formGroup]="form" *ngIf="userService.currentUser().role !== ROLES.CLIENT && !!form">
		<h3>Choix du client</h3>
		<div class="columns">
			<div class="column is-3">
				<mat-form-field>
					<mat-label>Région du client</mat-label>
					<mat-select formControlName="regionId" (selectionChange)="regionChanged()">
						<mat-option [value]="0">Toutes les régions</mat-option>
						<mat-option *ngFor="let r of userService.currentUser().regionalCommittees" [value]="r.id">
							{{r.title}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column">
				<mat-form-field>
					<mat-label>Client</mat-label>
					<input matInput type="text" [matAutocomplete]="auto" formControlName="customerSearch" >
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="clientDisplayName"
						(optionSelected)="updateCustomer($event)"
					>
						<mat-option *ngFor="let customer of filteredCustomers" [value]="customer">
							<div class="is-flex is-align-items-center">
								<mat-label style="flex:1">{{clientDisplayName(customer)}}</mat-label>
							</div>
						</mat-option>
						<mat-option [disabled]="true" *ngIf="filteredCustomers?.length === 0">
							Aucun résultat
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
		</div>
	</form>

	<!-- MESSAGE -->
	<div id="message" *ngIf="bookingService.booking().length > 0">
		<h3>Dites nous en plus sur vos envies</h3>
		<mat-form-field>
			<textarea matInput [(ngModel)]="bookingService.message" rows="12"></textarea>
		</mat-form-field>
	</div>

	<!-- BUTTONS -->
	<div class="buttons">
		<button
			class="button is-primary is-large"
			*ngIf="bookingService.booking().length > 0"
			[disabled]="userService.currentUser().role !== ROLES.CLIENT && bookingService.customerId() === 0"
			(click)="bookingService.submitDemand()"
		>
			<mat-icon>send</mat-icon>
			<span *ngIf="userService.currentUser().role === ROLES.CLIENT">Envoyer ma demande de réservation</span>
			<span *ngIf="userService.currentUser().role !== ROLES.CLIENT">Créer la demande de réservation</span>
		</button>

		<h6 *ngIf="userService.currentUser().role !== ROLES.CLIENT && bookingService.customerId() === 0">
			Veuillez choisir le client pour lequel vous réalisez la demande de réservation.
		</h6>
	</div>
</div>
