import {Injector, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dynamicPipe',
    standalone: false
})
export class DynamicPipePipe implements PipeTransform {


    constructor(private injector: Injector) {
    }

    transform(value: any, pipes: { pipe: Pipe, args?: any[] }[]): any {

        if (!pipes || !pipes.length) {
            return value;
        }

        pipes.forEach(pipe => {

            if (pipe.args !== undefined) {
                value = (this.injector.get(pipe.pipe) as PipeTransform).transform(value, ...pipe.args);
            } else {
                value = (this.injector.get(pipe.pipe) as PipeTransform).transform(value);
            }

        });

        return value;
    }

}
