import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PublicDay } from 'src/app/modules/public/models/public-calendar.model';
import { HelperService } from '../../services/helper.service';
import { CONSTANTS } from '../../../../../constants';
import { getHours } from 'date-fns';
import { CalendarEvent } from 'angular-calendar';
import { EventDto, ReservationSlotDto } from '../../dtos/event/event.dto';
import moment from 'moment';
import _ from 'lodash';


@Component({
    selector: 'app-slot-booking-calendar',
    templateUrl: './slot-booking-calendar.component.html',
    styleUrl: './slot-booking-calendar.component.scss',
    standalone: false
})

export class SlotBookingCalendar implements OnInit, OnChanges {

	@Input("mode") mode: 'PUBLIC' | 'PRIVATE';
	@Input("event") event: EventDto;
	@Output('select') select = new EventEmitter();

	readonly CONSTANTS = CONSTANTS;
	readonly hourSegments = 5;

	refresh = new Subject();
	slots: CalendarEvent[];
	days: PublicDay[] = [];

	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService
	) {}

	ngOnInit(): void {
		this.mapSlots();
		this.setDays();
	}

	ngOnChanges(changes: SimpleChanges): void {
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	eventClicked(evt) {
		if (this.mode === 'PUBLIC') {
			this.selectForReservation(evt.event)
		} else {
			this.allowReservationRemoval(evt.event)
		}
	}

	private getCalendarEvent(slot: ReservationSlotDto): CalendarEvent {
		return {
			id: slot.id,
			start: moment(slot.startDate, CONSTANTS.DATE_HOUR_TRANSACTION_FORMAT).toDate(),
			end: moment(slot.endDate, CONSTANTS.DATE_HOUR_TRANSACTION_FORMAT).toDate(),
			title: slot.booked ? this.mode === 'PUBLIC'
					? 'Réservé.'
					: 'Réservé par ' + slot.guestName
					: "Libre",
			color: {
				primary: slot.booked ? '#ccc' : 'blue',
				secondary:  slot.booked ? '#ccc' : '#FFF'
			},
			draggable: false,
			resizable: {
				beforeStart: false,
				afterEnd: false
			}
		}
	}

	private mapSlots(): void {
		this.slots = this.event.reservationSlotDtos
			.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
			.map(slot => this.getCalendarEvent(slot));
	}

	private setDays(): void {
		const uniqDays = _.groupBy(this.slots, (evt) => {
			const clone = _.clone(evt.start)
			clone.setHours(0, 0, 0, 0)
			return clone;
		})
		this.days = Object.keys(uniqDays).map(key => {
			const slots = uniqDays[key];
			const endHour = slots[slots.length-1].end.getHours() === 0
				? 24
				: slots[slots.length-1].end.getHours();

			return {
				date: new Date(key),
				dateTitle: this.helper.fullDateFr(new Date(key)),
				slots,
				startHour: slots[0].start.getHours(),
				endHour
			}
		})
	}


	private selectForReservation(calendarEvent: CalendarEvent) {
		this.slots.forEach((e: CalendarEvent) => {
			const rs = this.event.reservationSlotDtos.find(rs => rs.id === e.id)

			if (rs.booked) {
				return;
			}

			e.color.primary = "blue";
			e.color.secondary = "#FFF";
			e.color.secondaryText = "#1e90ff";

			if (rs.id === calendarEvent.id) {
				this.select.emit(rs);
			}
		})
	}

	private allowReservationRemoval(calendarEvent: CalendarEvent) {
		const rs = this.event.reservationSlotDtos.find(rs => rs.id === calendarEvent.id)
		if (rs.booked) {
			this.select.emit(rs)
		}
	}

}
