<section *ngIf="!!form">
	<div class="sub-header">
		<h1>Edition devis</h1>
	</div>

	<form [formGroup]="form" *ngIf="form && booking">
		<div id="table">
			<div class="line" style="margin-bottom: 1.5rem;">
				<div class="line-main">
					<div class="columns is-vcentered" id="table-header">
						<div class="column is-2">Désignation de la prestation</div>
						<div class="column is-2">Date</div>
						<div class="column is-1">Offre</div>
						<div class="column is-1">Parcipants additionnels</div>
						<div class="column is-1">Prix par défaut</div>
						<div class="column is-1">Heures oranges (+{{proposal.orangeIncreasePercent}}%)</div>
						<div class="column is-1">Heures rouges (+{{proposal.redIncreasePercent}}%)</div>
						<div class="column is-1">Prix unitaire</div>
						<div class="column is-1">Remise %</div>
						<div class="column is-1">Prix HT</div>
					</div>
				</div>
				<div class="plus"></div>
			</div>
			<ng-container formArrayName="lines" *ngIf="lines">
				<div class="line" *ngFor="let line of lines.controls; let i = index;">
					<div class="line-main">
						<div class="columns" [formGroupName]="i">
							<!-- ACTIVITY -->
							<div class="column is-2">
								<div class="activity-name">
									{{line.get('activityName').value}}
									<span *ngIf="line.get('activityType').value === ACTIVITY_TYPES.COLLECTIVE">
										<BR/>{{getMaxParticipants(i)}} participants
											<span *ngIf="line.get('extraParticipants').value > 0">(+{{line.get('extraParticipants').value}})</span>
									</span>
								</div>
							</div>
							<!-- DATE -->
							<div class="column is-2">
								<div *ngIf="line.get('activityType').value !== ACTIVITY_TYPES.FICHE">
									{{helper.fullDateFr(line.get('date').value)}}
									<BR />
									de {{helper.hourFr(line.get('date').value)}} à {{helper.hourFr(getEndHour(i))}}
								</div>
								<i *ngIf="line.get('activityType').value === ACTIVITY_TYPES.FICHE">N/A</i>
							</div>
							<!-- OFFRE -->
							<div class="column is-1">
								<mat-form-field subscriptSizing="dynamic" *ngIf="line.get('activityType').value !== ACTIVITY_TYPES.AUTRE">
									<mat-select
										formControlName="offerId"
										[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : '' "
										>
										<mat-option *ngFor="let o of proposal.lines[i].provision.activity.offers"
											[value]="o.id">
											<span *ngIf="line.get('activityType').value != ACTIVITY_TYPES.FICHE">
												{{Math.round(o.sessionDuration / 60)}}h
											</span>
											<span *ngIf="line.get('activityType').value === ACTIVITY_TYPES.FICHE">
												{{o.sessionDuration}} fiches
											</span>
										</mat-option>
									</mat-select>
								</mat-form-field>
								<i *ngIf="line.get('activityType').value === ACTIVITY_TYPES.AUTRE">N/A</i>
							</div>

							<div class="column is-1">
								<div id="extra-participants" *ngIf="line.get('activityType').value === ACTIVITY_TYPES.COLLECTIVE">
									<button
										class="button is-small is-light minus"
										tooltipStr="Retirer un participant"
										(click)="addExtraParticipant(i, -1)"
										[disabled]="getLineFromLineIndex(i).extraParticipants <= 0 || isLocked"
										[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : '' "
									>
										-
									</button>

									{{line.get('extraParticipants').value}}

									<button
										class="button is-small is-light add"
										tooltipStr="Ajouter un participant"
										[disabled]="isLocked || line.get('extraParticipants').value >= 5"
										(click)="addExtraParticipant(i, 1)"
										[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : '' "
									>
										+
									</button>
								</div>
								<i *ngIf="line.get('activityType').value !== ACTIVITY_TYPES.COLLECTIVE">N/A</i>
							</div>

							<!-- DEFAULT PRICE -->
							<div class="column is-1">
								{{getLineFromLineIndex(i)?.defaultPrice.toFixed(2)}}€
							</div>

							<!-- BY NIGHT RATE -->
							<div class="column is-1">
								<i *ngIf="line.get('activityType').value === ACTIVITY_TYPES.FICHE">N/A</i>
								<span *ngIf="line.get('activityType').value !== ACTIVITY_TYPES.FICHE">
									{{(Math.round(getLineFromLineIndex(i).percentageMajorationLate * 10000) / 100).toFixed(2)}}%
								</span>
							</div>

							<!-- LATE RATE -->
							<div class="column is-1">
								<i *ngIf="line.get('activityType').value === ACTIVITY_TYPES.FICHE">N/A</i>
								<span *ngIf="line.get('activityType').value !== ACTIVITY_TYPES.FICHE">
									{{(Math.round(getLineFromLineIndex(i).percentageMajorationNight * 10000) / 100).toFixed(2)}}%
								</span>
							</div>

							<!-- UNIT PRICE -->
							<div class="column is-1">
								<div *ngIf="line.get('activityType').value !== ACTIVITY_TYPES.AUTRE">
									{{getUnitPrice(i).toFixed(2)}}€
								</div>
								<mat-form-field subscriptSizing="dynamic"
									*ngIf="line.get('activityType').value === ACTIVITY_TYPES.AUTRE">
									<input matInput type="number" formControlName="onDemandPrice"
										[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : '' ">
								</mat-form-field>
							</div>
							<!-- DISCOUNT % -->
							<div class="column is-1">
								<mat-form-field
									subscriptSizing="dynamic"
									[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : '' ">
									<input matInput
										type="number"
										formControlName="priceVariation"
										[max]="bookingService.getMaxDiscountPercentage(getTotalHT())"
										[min]="0"
									>
								</mat-form-field>
							</div>
							<!-- FINAL PRICE -->
							<div class="column is-1">
								<div>{{getLineTotalPrice(i).toFixed(2)}}€</div>
							</div>
						</div>
					</div>
					<div class="plus">
						<button class="button is-small is-primary"
							[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : 'Doubler la prestation'"
							[disabled]="bookingService.isBookingLocked(booking)"
							(click)="copyLine(i)"
							>
							<mat-icon style="font-size: 14px;">plus_one</mat-icon>
						</button>

						<button class="button is-small is-inverted is-danger"
							[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : 'Supprimer la prestation'"
							(click)="deleteLine(i)"
							[disabled]="bookingService.isBookingLocked(booking)"
							*ngIf="lines.length > 1"
							>
							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</div>
			</ng-container>
		</div>


		<div class="columns" style="margin-top: 4rem; padding-top: 3rem; font-weight: bold; border-top: 1px solid #000">
			<div class="column is-9 checkbox-line" style="height: 74px">
				<mat-checkbox
					formControlName="applyApplicationFees"
					[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : '' "
					[disabled]="isLocked">
					<span>Appliquer frais de dossier (2% par défaut)</span>
				</mat-checkbox>
			</div>
			<ng-container *ngIf="form.get('applyApplicationFees').value">
				<div class="column is-2">
					<mat-form-field subscriptSizing="dynamic">
						<input matInput type="number"
							formControlName="applicationFees"
							[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : 'Les frais de dossier apparaiteront toujours sur la première facture émise.'">
					</mat-form-field>
				</div>
				<div class="column">€</div>
			</ng-container>
		</div>

		<div class="columns">
			<div class="column is-10 checkbox-line"><span style="margin-left: 44px;">TOTAL HT</span></div>
			<div class="column is-1" style="text-align: right;">{{getTotalHT().toFixed(2)}}€</div>
		</div>

		<div class="columns" style="margin-bottom: 0; padding: 0; margin-top: 0rem;">
			<div class="column is-10 checkbox-line">
				<mat-checkbox
					formControlName="applyVAT"
					[tooltipStr]="isLocked ? bookingService.whyBookingIsLocked(booking) : '' ">
					<span>TVA {{proposal.vatPercentage}}%</span>
				</mat-checkbox>
			</div>
			<div class="column is-1" style="text-align: right;">{{this.form.get('applyVAT').value ? getVat().toFixed(2)+"€" : ""}}
			</div>
		</div>

		<div class="columns" style="font-weight: bold; margin-bottom: 0; margin-top: 1.5rem;">
			<div class="column is-9 checkbox"><span style="margin-left: 44px;">TOTAL TTC</span></div>
			<div class="column is-2" style="font-size: 26px; text-align: right;">{{getTotalTTC().toFixed(2)}}€</div>
		</div>
	</form>

	<div id="buttons">

		<button class="button is-danger is-inverted"
			[disabled]="!form.valid"
			(click)="pdf()"
			[ngClass]="{'is-fullwidth' : appService.isMobile() }"
			>
			<mat-icon>picture_as_pdf</mat-icon>
			<span>Exporter en PDF</span>
		</button>

		<button class="button is-primary"
			*ngIf="booking.status === BOOKING_STATUS.NON_TRAITEE
				&& userService.currentUser().role === ROLES.EMPLOYE_FEDE"
			(click)="setStatus(BOOKING_STATUS.DEVIS_SOUMIS_A_VALIDATION)"
			[disabled]="bookingService.isBookingLocked(booking)"
			[tooltipStr]="bookingService.whyBookingIsLocked(booking)"
			[ngClass]="{'is-fullwidth' : appService.isMobile() }"
			>
			<mat-icon>send</mat-icon>
			<span>Soumettre à validation</span>
		</button>

		<button class="button is-info"
			*ngIf="BOOKING_STATUS_OBJ[booking.status].order < BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_VALIDE].order
				&& booking.status !== BOOKING_STATUS.REFUSEE
			 	&& userService.currentUser().role === ROLES.ADMIN_FEDE"
			(click)="setStatus(BOOKING_STATUS.DEVIS_VALIDE)"
			[disabled]="bookingService.isBookingLocked(booking)"
			[tooltipStr]="bookingService.whyBookingIsLocked(booking)"
			[ngClass]="{'is-fullwidth' : appService.isMobile() }"
			>
			<mat-icon>check_circle</mat-icon>
			<span>Valider le devis</span>
		</button>

	</div>
</section>
