import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuillModule } from 'ngx-quill';
import { InscriptionComponent } from '../public/screens/inscription/inscription.component';
import { ReinitialiserMotDePasseComponent } from '../public/screens/reinitialiser-mot-de-passe/reinitialiser-mot-de-passe.component';
import { IsAdmin } from '../shared/guards/is-admin.guard';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { EventCalendarModalComponent } from './modals/event-calendar-modal/event-calendar-modal.component';
import { ImageCropperModal } from './modals/image-cropper-modal/image-cropper-modal.component';
import { InvoiceModal } from './modals/invoice-modal/invoice.modal';
import { ActivityResolver } from './resolvers/activity.resolver';
import { BookingResolver } from './resolvers/booking.resolver';
import { BookingsResolver } from './resolvers/bookings.resolver';
import { CommercialProposalResolver } from './resolvers/commercial-proposal.resolver';
import { CustomersResolver } from './resolvers/customers.resolver';
import { DocumentsResolver } from './resolvers/documents.resolver';
import { InvoicesByBookingResolver } from './resolvers/invoices-by-booking.resolver';
import { InvoicesResolver } from './resolvers/invoices.resolver';
import { StatsResolver } from './resolvers/stats.resolver';
import { UserResolver } from './resolvers/user.resolver';
import { InsertDataComponent } from './screens/_insert-data/insert-data.component';
import { CalendrierComponent } from './screens/calendrier/calendrier.component';
import { CatalogueComponent } from './screens/catalogue/catalogue.component';
import { ActivityModalComponent } from './screens/detail-activite/activity-modal/activity-modal.component';
import { ActivityOffersModalComponent } from './screens/detail-activite/activity-offers-modal/activity-offers-modal.component';
import { DetailActiviteComponent } from './screens/detail-activite/detail-activite.component';
import { BookingLinkModal } from './screens/detail-demande/1_prestations-tab/booking-link-modal/booking-link.modal';
import { PrestationsTabComponent } from './screens/detail-demande/1_prestations-tab/prestations-tab.component';
import { OrganiserComponent } from './screens/detail-demande/2_organiser-tab/organiser.component';
import { DevisTabComponent } from './screens/detail-demande/3_devis-tab/devis-tab.component';
import { InvoiceComponent } from './screens/detail-demande/4_invoice-tab/invoice.component';
import { DocumentsTabComponent } from './screens/detail-demande/5_documents-tab/documents-tab.component';
import { DetailDemandeComponent } from './screens/detail-demande/detail-demande.component';
import { ListeDemandesComponent } from './screens/liste-demandes/liste-demandes.component';
import { ListeFacturesComponent } from './screens/liste-factures/liste-factures.component';
import { MesDemandesComponent } from './screens/mes-demandes/mes-demandes.component';
import { BookingSuccessModalComponent } from './screens/nouvelle-reservation/booking-success-modal/booking-success-modal.component';
import { NouvelleReservationComponent } from './screens/nouvelle-reservation/nouvelle-reservation.component';
import { StatistiquesComponent } from './screens/statistiques/statistiques.component';
import { SuccesReservationComponent } from './screens/succes-reservation/succes-reservation.component';
import { UtilisateurModal } from './screens/utilisateurs/utilisateur-modal/utilisateur.modal';
import { UtilisateursComponent } from './screens/utilisateurs/utilisateurs.component';


@NgModule({
	declarations: [
		DashboardComponent,
		CatalogueComponent,
		CalendrierComponent,
		ActivityModalComponent,
		DetailActiviteComponent,
		ImageCropperModal,
		ActivityOffersModalComponent,
		ListeDemandesComponent,
		MesDemandesComponent,
		NouvelleReservationComponent,
		SuccesReservationComponent,
		DetailDemandeComponent,
		InvoiceComponent,
		OrganiserComponent,
		DevisTabComponent,
		DocumentsTabComponent,
		PrestationsTabComponent,
		EventCalendarModalComponent,
		InsertDataComponent,
		StatistiquesComponent,
		UtilisateursComponent,
		UtilisateurModal,
		InvoiceModal,
		BookingSuccessModalComponent,
		ListeFacturesComponent,
		BookingLinkModal
	],
	imports: [
		RouterModule.forChild([{
				path: '',
				redirectTo: 'catalogue',
				pathMatch: 'full'
			}, {
				path: 'statistiques',
				canActivate: [IsAdmin],
				component: StatistiquesComponent,
				runGuardsAndResolvers: 'always',
				resolve: { stats: StatsResolver }
			}, {
				path: 'calendrier',
				canActivate: [IsAdmin],
				component: CalendrierComponent
			}, {
				path: 'catalogue',
				component: CatalogueComponent
			}, {
				path: 'catalogue/:id',
				component: DetailActiviteComponent,
				resolve: { activity: ActivityResolver },
				runGuardsAndResolvers: 'always'
			}, {
				path: 'demandes',
				canActivate: [IsAdmin],
				component: ListeDemandesComponent,
				runGuardsAndResolvers: 'always',
				resolve: {
					bookingsPage: BookingsResolver,
					customersPage: CustomersResolver
				}
			}, {
				path: 'mes-demandes',
				component: MesDemandesComponent,
				runGuardsAndResolvers: 'always',
				resolve: { bookingsPage: BookingsResolver }
			}, {
				path: 'demandes/:id',
				component: DetailDemandeComponent,
				resolve: {
					booking: BookingResolver,
					proposal: CommercialProposalResolver
				 },
				runGuardsAndResolvers: 'always',
				children: [{
					path: 'prestations',
					component: PrestationsTabComponent,
				}, {
					path: 'organiser',
					canActivate: [IsAdmin],
					component: OrganiserComponent
				}, {
					path: 'devis',
					canActivate: [IsAdmin],
					component: DevisTabComponent,
					runGuardsAndResolvers: 'always',
				}, {
					path: 'paiement',
					canActivate: [IsAdmin],
					runGuardsAndResolvers: 'always',
					component: InvoiceComponent,
					resolve: {invoices: InvoicesByBookingResolver}
				},{
					path: 'documents',
					runGuardsAndResolvers: 'always',
					component: DocumentsTabComponent,
					resolve: { documents: DocumentsResolver }
				}, {
					path: '**',
					redirectTo: 'prestations'
				}]
			}, {
				path: 'nouvelle-demande',
				component: NouvelleReservationComponent
			}, {
				path: 'nouvelle-demande/succes',
				component: SuccesReservationComponent
			}, {
				path: 'utilisateurs',
				component: UtilisateursComponent,
				runGuardsAndResolvers: 'always',
				resolve: { users: UserResolver },
			}, {
				path: 'compte',
				component: InscriptionComponent
			}, {
				path: 'compte/update-pw',
				component: ReinitialiserMotDePasseComponent
			}, {
				path: 'insert-data',
				canActivate: [IsAdmin],
				component: InsertDataComponent
			}, {
				path: 'factures',
				component: ListeFacturesComponent,
				runGuardsAndResolvers: 'always',
				canActivate: [IsAdmin],
				resolve: {
					invoicesPage: InvoicesResolver,
					customersPage: CustomersResolver
				}
			}
		]),
		SharedModule,
		QuillModule.forRoot({
			// modules: {
			// 	toolbar: [
			// 		['bold', 'italic', 'underline'],
			// 		[{ 'list': 'bullet' }],
			// 		[{ 'indent': '-1' }, { 'indent': '+1' }],
			// 	]
			// }
		})
	]
})

export class DashboardModule { }
