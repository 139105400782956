import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { AppService } from 'src/app/modules/shared/services/app.service';

@Component({
    selector: 'app-home-main',
    templateUrl: './home-main.component.html',
    styleUrls: ['./home-main.component.scss'],
    standalone: false
})

export class HomeMainComponent {

	environment = environment;

	constructor(
		public appService: AppService
	) {}

}
