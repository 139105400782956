

import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { accountExistsValidator } from 'src/app/modules/shared/validators/account-exists.validator';

@Component({
    selector: 'app-request-recovery',
    templateUrl: './request-recovery.component.html',
    styleUrls: ['./request-recovery.component.scss'],
    standalone: false
})
export class RequestRecoveryComponent implements OnInit {

	email: FormControl;

	constructor(
		public dialogRef: DialogRef,
		private userService: UserService,
		private authService: AuthService,
		private notificationService: NotificationService
	) { }

	ngOnInit() {
		this.email = new FormControl(
			!!this.dialogRef.config.data.email ? this.dialogRef.config.data.email: "", {
			validators: [Validators.required, Validators.email],
			asyncValidators: [accountExistsValidator(this.userService)],
			updateOn: 'change'
		});
		this.email.markAllAsTouched();
	}

	async resetPassword() {
		await firstValueFrom(this.authService.requestPasswordReset(this.email.value))
		this.notificationService.showSuccess('Vérifiez votre e-mail pour les instructions de réinitialisation', 'Succès !')
		this.dialogRef.close();
	}
}
