<section *ngIf="activity">
	<div id="banner">
		<app-responsive-image [maxHeight]="appService.isMobile() ? '26dvh' : '78dvh'"
			[source]="activity.banner"></app-responsive-image>

		<div id="admin" *ngIf="userService.isFederalAdmin()">
			<button class="button is-info" [ngClass]="{'is-fullwidth' : appService.isMobile() }"
				(click)="updateActivity()">
				<mat-icon style="font-size: 16px;">edit</mat-icon>
				<span>Modifier l'activité</span>
			</button>
		</div>
		<div class="mask">
			<h1>{{ activity.title ? activity.title : 'Non renseigné' }}</h1>
		</div>
	</div>

	<div id="update-info" *ngIf="userService.isFederalAdmin()">
		<span>Dernière modification le {{helper.dateFr(activity.modification)}} </span>
		<span>à {{helper.hourFr(activity.modification)}} </span>
		<span>par {{ activity.authorModification }}</span>
	</div>


	<p id="round-block" style="text-align: center;" *ngIf="activity.activityType === ACTIVITY_TYPES.AUTRE">
		{{activity.offers[0].sessionConditions}}
	</p>

	<!-- DESC + OBJ -->
	<ng-container *ngIf="activity.activityType !== ACTIVITY_TYPES.AUTRE">
		<div id="round-block" class="columns">
			<div class="column is-4">
				<h1>Présentation</h1>
				<p *ngIf="!isEditingTextField" [innerHTML]="activity.presentation"></p>
				<div class="editor" *ngIf="isEditingTextField">
					<quill-editor
						[modules]="EDITOR_OPTIONS"
						placeholder="Tapez votre présentation ici..."
						[(ngModel)]="activity.presentation">
					</quill-editor>
				</div>
			</div>

			<div class="column is-4">
				<h1 style="margin-bottom: .55rem;">Objectifs</h1>
				<p *ngIf="!isEditingTextField" [innerHTML]="activity.objectives"></p>
				<div class="editor" *ngIf="isEditingTextField">
					<quill-editor
						[modules]="EDITOR_OPTIONS"
						placeholder="Tapez votre présentation ici..."
						[(ngModel)]="activity.objectives">
					</quill-editor>
				</div>
			</div>
			<div class="column is-4">
				<h1>Modalités</h1>
				<p *ngIf="!isEditingTextField" [innerHTML]="activity.modalities"></p>
				<div class="editor" *ngIf="isEditingTextField">
					<quill-editor
						[modules]="EDITOR_OPTIONS"
						placeholder="Tapez votre présentation ici..."
						[(ngModel)]="activity.modalities">
					</quill-editor>
				</div>
			</div>
		</div>


		<div id="edit-buttons">
			<button class="button is-info"
				*ngIf="userService.isFederalAdmin() && !isEditingTextField"
				[ngClass]="{'is-fullwidth' : appService.isMobile() }"
				(click)="setPresentationEdit()"
			>
				<mat-icon style="font-size: 16px;">edit</mat-icon>
				<span>Modifier la présentation</span>
			</button>

			<button class="button"
				*ngIf="userService.isFederalAdmin() && isEditingTextField"
				[ngClass]="{'is-fullwidth' : appService.isMobile() }"
				(click)="cancelTextEdit()"
			>
				<mat-icon style="font-size: 16px;">close</mat-icon>
				<span>Annuler</span>
			</button>

			<button class="button is-primary"
				style="margin-left: 1rem;"
				*ngIf="userService.isFederalAdmin() && isEditingTextField"
				[ngClass]="{'is-fullwidth' : appService.isMobile() }"
				(click)="updatePresentation()"
			>
				<mat-icon style="font-size: 16px;">save</mat-icon>
				<span>Enregistrer les modifications</span>
			</button>
		</div>
	</ng-container>

	<!-- TESTIMONIES -->
	<!-- <div>
		<button>
		</button>
	</div> -->

	<div id="price">
		<!-- BOOK BUTTON -->
		<div id="book-area" [ngClass]="{ 'add': bookingService.activityIsSelected(activity) }" (click)="book()">
			<button class="button is-large is-primary add-to-cart">
				<img src="/assets/svg/logo.svg" style="height: 40px;">
				<span class="btn-text">J'ajoute la prestation à ma selection</span>
			</button>
		</div>

		<button
			*ngIf="userService.currentUser().role === ROLES.ADMIN_FEDE && activity.activityType !== ACTIVITY_TYPES.AUTRE"
			class="button is-info" [ngClass]="{'is-fullwidth' : appService.isMobile() }" (click)="openOffersModal()">
			<mat-icon style="font-size: 16px;">edit</mat-icon>
			<span>Administrer les offres</span>
		</button>

		<p class="panier">
			<ng-container *ngIf="bookingService.activityIsSelected(activity)">
				L'activité a été ajouté à votre sélection. <BR />
				Rendez-vous dans l'onglet <a routerLink="/dashboard/nouvelle-demande">Nouvelle réservation</a> pour
				finaliser la demande.
			</ng-container>
		</p>
	</div>


	<div id="rc" *ngIf="userService.currentUser().role === ROLES.ADMIN_FEDE && activity.activityType !== ACTIVITY_TYPES.AUTRE">
		<h1>Régions proposants cette activité</h1>

		<div class="columns is-multiline">
			<div class="column is-half is-full-mobile"
				*ngFor="let rc of regionalCommittees">
				<mat-checkbox
					[(ngModel)]="rc.hasActivity"
					[disabled]="activity.id === 43"
					(ngModelChange)="toggleCommitteeActivity(rc.id)"
				></mat-checkbox>
				<span>{{ rc.title }}</span>
			</div>
		</div>
	</div>

</section>
