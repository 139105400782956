import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import _ from 'lodash';
import moment from 'moment';
import { Subscription, firstValueFrom } from 'rxjs';
import { EventDto, ReservationSlotDto } from 'src/app/modules/shared/dtos/event/event.dto';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { CONSTANTS } from 'src/constants';
import { PublicDay } from '../../models/public-calendar.model';
import { ReservationFormModal } from './reservation-form-modal/reservation-form.modal';

@Component({
    selector: 'app-reserver',
    templateUrl: './reserver.component.html',
    styleUrl: './reserver.component.scss',
    standalone: false
})

export class ReserverComponent implements OnInit, OnDestroy {

	event: EventDto;
	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService,
		public appService: AppService,
		private activatedRoute: ActivatedRoute,
		private eventService: EventService,
		private dialog: MatDialog
	) {}

	async ngOnInit() {
		this.subscriptions.push(
			this.activatedRoute.params.subscribe(async (p) => {
				this.event = await firstValueFrom(this.eventService.getPublicEvent(parseInt(atob(p.eventId))));
			})
		)
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onEventClick(ev) {
		this.dialog.open(ReservationFormModal, { data: { slot: ev }})
	}
}
