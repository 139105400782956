<section class="container-fluid">
	<div class="header">
		<h1>Liste des affaires</h1>

		<form class="btn-group" [formGroup]="form">
			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>État de la commande</mat-label>
					<mat-select formControlName="bookingStatus" (selectionChange)="reloadContent()">
						<mat-option [value]="0">Tous les états</mat-option>
						<mat-option *ngFor="let key of Object.keys(BOOKING_STATUS_OBJ)" [value]="key">
							{{BOOKING_STATUS_OBJ[key].label}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="custom-field" style="max-width: 280px; width: 280px;">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Zone géographique</mat-label>
					<mat-select formControlName="zoneId" (selectionChange)="zoneChanged()">
						<mat-option [value]="0">Toutes les zones</mat-option>
						<mat-option *ngFor="let zone of userService.allZones()" [value]="zone.id">
							{{zone.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<!-- <div class="custom-field" style="max-width: 280px; width: 280px;">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Région</mat-label>
					<mat-select formControlName="regionId" (selectionChange)="regionChanged()">
						<mat-option [value]="0">Toutes les régions</mat-option>
						<mat-option *ngFor="let region of userService.currentUser().regionalCommittees" [value]="region.id">
							{{region.title}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div> -->

			<div class="custom-field" style="max-width: 600px; width: 600px;">
				<mat-form-field>
					<mat-label>Client</mat-label>
					<input matInput type="text" [matAutocomplete]="auto" formControlName="customerSearch">
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="clientDisplayName"
						(optionSelected)="customerChanged($event)"
					>
						<mat-option *ngFor="let customer of filteredCustomers" [value]="customer">
							<div class="is-flex is-align-items-center">
								<mat-label style="flex:1">{{clientDisplayName(customer)}}</mat-label>
							</div>
						</mat-option>
						<mat-option [disabled]="true" *ngIf="filteredCustomers?.length === 0">
							Aucun résultat
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>

			</div>
		</form>
	</div>

	<p id="no-response" *ngIf="pageResponse?.totalElements === 0">Aucune demande pour l'instant.</p>

	<div class="box" *ngIf="pageResponse?.totalElements > 0">

		<mat-paginator
			*ngIf="pageResponse?.totalPages > 1"
			[length]="pageResponse?.totalElements"
			[pageSize]="pageResponse.size"
			[pageSizeOptions]="PAGINATION_OPTIONS"
			[pageIndex]="pageResponse?.number"
			(page)="pageChanged($event)">
		</mat-paginator>

		<div id="table-container">
			<table class="table is-striped" *ngIf="pageResponse?.content.length > 0">
				<thead>
					<tr>
						<td style="width: 100px">Référence devis</td>
						<td style="width: 100px">Date</td>
						<td style="width: 200px">Nom entreprise</td>
						<td style="width: 150px">Nom du contact</td>
						<td style="width: 150px">Département</td>
						<td style="width: 120px">Nb prestations</td>
						<td style="width: 100px">Montant devis HT</td>
						<td style="width: 170px">État de l'affaire</td>

					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let booking of pageResponse.content">
						<td><button class="button is-primary is-inverted" (click)="router.navigateByUrl('/dashboard/demandes/'+booking.id)">#{{booking.proposalReference}}</button></td>
						<td>{{helperService.dateFr(booking.date)}}</td>
						<td>{{booking.companyName}}</td>
						<td>{{booking.contactName}}</td>
						<td>{{booking.department}}</td>
						<td>{{booking.nbEvents}}</td>
						<td>{{booking.amountProposal}}</td>
						<td>
							<div class="status" [ngClass]="booking.bookingStatus">
								{{BOOKING_STATUS_OBJ[booking.bookingStatus].label}}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<mat-paginator *ngIf="pageResponse?.totalPages > 1"
				[length]="pageResponse?.totalElements"
				[pageSize]="pageResponse.size"
				[pageSizeOptions]="PAGINATION_OPTIONS"
				[pageIndex]="pageResponse?.number"
				(page)="pageChanged($event)">
		</mat-paginator>
	</div>

</section>
