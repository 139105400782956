import { Dialog } from '@angular/cdk/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { ActivityDto } from 'src/app/modules/shared/dtos/activity/activity.dto';
import { RegionalCommitteeHavingActivityDto } from 'src/app/modules/shared/dtos/regional-comittee.dto';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { ActivityService } from 'src/app/modules/shared/services/activity.service';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { RegionalCommitteeService } from 'src/app/modules/shared/services/regional-committee.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { CONSTANTS } from 'src/constants';
import { BookingSuccessModalComponent } from '../nouvelle-reservation/booking-success-modal/booking-success-modal.component';
import { ActivityModalComponent } from './activity-modal/activity-modal.component';
import { ActivityOffersModalComponent } from './activity-offers-modal/activity-offers-modal.component';
import { QuillConfig, QuillModule } from 'ngx-quill';


@Component({
    selector: 'app-detail-activite',
    templateUrl: './detail-activite.component.html',
    styleUrls: ['./detail-activite.component.scss'],
    standalone: false
})

export class DetailActiviteComponent implements OnInit, OnDestroy {
	readonly ROLES = ROLES;
	readonly ACTIVITY_TYPES = ACTIVITY_TYPES;
	readonly EDITOR_OPTIONS: QuillModule = {
			toolbar: [
						['bold', 'italic', 'underline'],
						[{ 'list': 'bullet' }],
						[{ 'indent': '-1' }, { 'indent': '+1' }],
						['clean']
					]

	}
	activity: ActivityDto;
	isEditingTextField = false;
	descriptionBackup: {presentation: string, objectives: string, modalities: string};
	regionalCommittees: RegionalCommitteeHavingActivityDto[] = [];

	private subscriptions: Subscription[] = [];

	constructor(
		public authService: AuthService,
		public appService: AppService,
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public helper: HelperService,
		public bookingService: BookingService,
		public userService: UserService,
		private dialog: Dialog,
		private activityService: ActivityService,
		private regionalCommitteeService: RegionalCommitteeService,
	) { }

	ngOnInit() {
		this.subscriptions.push(
			this.activatedRoute.data.subscribe((data: Data) => {
				this.activity = data.activity;
				this.loadRegionsConfiguration();
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	updateActivity(): void {
		this.dialog.open(ActivityModalComponent, {
			minWidth: this.appService.isMobile() ? '100vw' : '750px',
			data: {
				activity: this.activity,
				saveCloseCallback: () => {
					this.bookingService.refreshThemes();
					this.appService.reloadPage();
				},
				deleteCallback: () => this.router.navigate(['dashboard', 'catalogue'])
			}
		});
	}

	setPresentationEdit() {
		this.descriptionBackup = {
			presentation: this.activity.presentation,
			objectives: this.activity.objectives,
			modalities: this.activity.modalities
		}
		this.isEditingTextField = true;
	}

	cancelTextEdit() {
		this.activity.presentation = this.descriptionBackup.presentation;
		this.activity.objectives = this.descriptionBackup.objectives;
		this.activity.modalities = this.descriptionBackup.modalities;
		this.isEditingTextField = false;
	}

	book(): void {
		if (!this.bookingService.activityIsSelected(this.activity)) {
			this.bookingService.booking().push({
				activity: this.activity,
				date: this.bookingService.nextAvailableDate,
				hour: this.bookingService.nextAvailableDate,
				offer: this.activity.offers[0]
			})

			// this.notificationService.showSuccess("L'activité a été ajouté à votre réservation", 'Succès');
			// this.router.navigate(['dashboard', 'catalogue']);

			this.dialog.open(BookingSuccessModalComponent, {})
		}

	}

	async updatePresentation() {
		if (confirm(CONSTANTS.YOU_SURE)) {
			await firstValueFrom(this.activityService.updateActivityText(this.activity.id, this.activity));
			this.appService.reloadPage();
			this.isEditingTextField = false;
			this.descriptionBackup = null;
		}
	}



	async toggleCommitteeActivity(regionId: number, reloadPage?: boolean) {
		await firstValueFrom(this.regionalCommitteeService.toggleRegionalCommitteeActivity(regionId, this.activity.id))
		if (reloadPage) {
			this.bookingService.refreshThemes();
			this.appService.reloadPage();
		}
	}

	openOffersModal(): void {
		this.dialog.open(ActivityOffersModalComponent, {
			minWidth: this.appService.isMobile() ? '100vw' : '1000px',
			disableClose: true,
			data: {
				activity: this.activity,
				saveCloseCallback: () => this.appService.reloadPage()
			}
		});
	}

	getCleanPrice(price: number | string): string {
		if (Math.round(price as number) !== price) {
			//@ts-ignore
            price = price.toFixed(2).replace(".", ",");
        }
		return price as string;
	}

	private loadRegionsConfiguration() {
		if (this.userService.currentUser().role === ROLES.ADMIN_FEDE) {
			this.loadAllRegionsWithActivityStatus();
		}
	}

	private async loadAllRegionsWithActivityStatus() {
		this.regionalCommittees = await firstValueFrom(
			this.regionalCommitteeService.getRegionalCommitteesHavingActivityId(this.activity.id)
		)
	}
}
