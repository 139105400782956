import { Component, Input, OnInit } from '@angular/core';
import { ImageFieldType } from '../../models/FieldConfig';


@Component({
    selector: 'app-image-field',
    templateUrl: './image-field.component.html',
    styleUrls: ['./image-field.component.css'],
    standalone: false
})

export class ImageFieldComponent implements OnInit {

    @Input() value;
    @Input() options: ImageFieldType;
    @Input() objectValue: any;
    @Input() param: any;

    constructor() {}

    ngOnInit() {
    }

	ngOnChange() {
	}

}
