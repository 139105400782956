import moment from 'moment';

export interface TemporalVariableDto {
	applyVatByDefault: boolean;
	current: boolean;
	endDate: string;
	startDate: string;
	orangeHoursEveningEndHour: number;
	orangeHoursEveningEndMinute: number;
	orangeHoursEveningStartHour: number;
	orangeHoursEveningStartMinute: number;
	orangeHoursIncreasePercent: number;
	orangeHoursMorningEndHour: number;
	orangeHoursMorningEndMinute: number;
	orangeHoursMorningStartHour: number;
	orangeHoursMorningStartMinute: number;
	redHoursEveningStartHour: number;
	redHoursEveningStartMinute: number;
	redHoursIncreasePercent: number;
	redHoursNextDayEndHour: number;
	redHoursNextDayEndMinute: number;
	vatPercent: number;
}


export class TemporalVariable {
	private applyVatByDefault: boolean;
	private current: boolean;
	private endDate: string;
	private startDate: string;
	private orangeHoursEveningEndHour: number;
	private orangeHoursEveningEndMinute: number;
	private orangeHoursEveningStartHour: number;
	private orangeHoursEveningStartMinute: number;
	private orangeHoursIncreasePercent: number;
	private orangeHoursMorningEndHour: number;
	private orangeHoursMorningEndMinute: number;
	private orangeHoursMorningStartHour: number;
	private orangeHoursMorningStartMinute: number;
	private redHoursEveningStartHour: number;
	private redHoursEveningStartMinute: number;
	private redHoursIncreasePercent: number;
	private redHoursNextDayEndHour: number;
	private redHoursNextDayEndMinute: number;
	private vatPercent: number;

	get startAt() {
		return moment(this.startDate);
	}

	get endAt() {
		return moment(this.endDate);
	}

	get isCurrent() {
		return this.current;
	}

	constructor(dto: TemporalVariableDto) {
		this.applyVatByDefault = dto.applyVatByDefault;
		this.current = dto.current;
		this.endDate = dto.endDate;
		this.startDate = dto.startDate;
		this.orangeHoursEveningEndHour = dto.orangeHoursEveningEndHour;
		this.orangeHoursEveningEndMinute = dto.orangeHoursEveningEndMinute;
		this.orangeHoursEveningStartHour = dto.orangeHoursEveningStartHour;
		this.orangeHoursEveningStartMinute = dto.orangeHoursEveningStartMinute;
		this.orangeHoursIncreasePercent = dto.orangeHoursIncreasePercent;
		this.orangeHoursMorningEndHour = dto.orangeHoursMorningEndHour;
		this.orangeHoursMorningEndMinute = dto.orangeHoursMorningEndMinute;
		this.orangeHoursMorningStartHour = dto.orangeHoursMorningStartHour;
		this.orangeHoursMorningStartMinute = dto.orangeHoursMorningStartMinute;
		this.redHoursEveningStartHour = dto.redHoursEveningStartHour;
		this.redHoursEveningStartMinute = dto.redHoursEveningStartMinute;
		this.redHoursIncreasePercent = dto.redHoursIncreasePercent;
		this.redHoursNextDayEndHour = dto.redHoursNextDayEndHour;
		this.redHoursNextDayEndMinute = dto.redHoursNextDayEndMinute;
		this.vatPercent = dto.vatPercent;
	}

	isRed(date: Date) {
		const todayMorningBottomLimit = moment(date).clone().set('hours', 0).set('minutes', 0).set('seconds', 0);
		const todayMorningTopLimit = moment(date).clone().set('hours', this.redHoursNextDayEndHour).set('minutes', this.redHoursNextDayEndMinute);
		const todayEveningLimit = moment(date).clone().set('hours', this.redHoursEveningStartHour).set('minutes', this.redHoursEveningStartMinute)
		const tomorrowMorningLimit = moment(date).clone().add(1, 'day').set('hours', this.redHoursNextDayEndHour).set('minutes', this.redHoursNextDayEndMinute);

		return this.isBetween(moment(date).clone(), todayMorningBottomLimit, todayMorningTopLimit)
			|| this.isBetween(moment(date).clone(), todayEveningLimit, tomorrowMorningLimit);
	}


	isOrange(date: Date) {
		const todayMorningBottomLimit = moment(date).clone().set('hours', this.orangeHoursMorningStartHour).set('minutes', this.orangeHoursMorningStartMinute);
		const todayMorningTopLimit = moment(date).clone().set('hours', this.orangeHoursMorningEndHour).set('minutes', this.orangeHoursMorningEndMinute);
		const todayEveningBottomLimit = moment(date).clone().set('hours', this.orangeHoursEveningStartHour).set('minutes', this.orangeHoursEveningStartMinute)
		const todayEveningTopLimit = moment(date).clone().add(1, 'day').set('hours', this.orangeHoursEveningEndHour).set('minutes', this.orangeHoursEveningEndMinute);

		return this.isBetween(moment(date).clone(), todayMorningBottomLimit, todayMorningTopLimit)
			|| this.isBetween(moment(date).clone(), todayEveningBottomLimit, todayEveningTopLimit);
	}

	private isBetween(
		date: moment.Moment,
		bottomLimit: moment.Moment,
		topLimit: moment.Moment
	) {
		return date.isSameOrAfter(bottomLimit) && date.isBefore(topLimit);
	}

}
