import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ZoneDto } from "../../shared/dtos/zone.dto";
import { UserService } from "../../shared/services/user.service";


@Injectable({ providedIn: 'root' })
export class ZoneResolver {

    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ZoneDto[]> {
		return this.userService.getAllZones()
    }

}
