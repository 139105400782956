import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarDayViewComponent, CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewComponent, CalendarView, CalendarWeekViewComponent } from 'angular-calendar';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { EventBooking } from 'src/app/modules/shared/models/event-booking';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { CONSTANTS } from 'src/constants';
import moment from 'moment';
import { CalendarData } from 'src/app/modules/shared/dtos/calendar.dto';
import { TemporalVariable } from 'src/app/modules/shared/models/temporal-variable';

@Component({
    selector: 'app-calendrier',
    templateUrl: './calendrier.component.html',
    styleUrl: './calendrier.component.scss',
    standalone: false
})

export class CalendrierComponent implements OnInit, OnDestroy {

	readonly CONSTANTS = CONSTANTS;
	readonly CalendarView = CalendarView;

    @ViewChild('monthComponent') monthComponent: CalendarMonthViewComponent;
    @ViewChild('weekComponent') weekComponent: CalendarWeekViewComponent;
    @ViewChild('dayComponent') dayComponent: CalendarDayViewComponent;

    calendarView: CalendarView = CalendarView.Week;
    viewDate: Date = new Date();
    refresh: Subject<any> = new Subject();
	events: CalendarEvent[] = [];
	activeDayIsOpen = true;
	currentPeriod: { start: Date, end: Date };
	dataLoaded = false;
	currentRegionId: number;
	calendarData: CalendarData;
	dayEvent: CalendarEvent;
	regionIsSet = false;
	form: UntypedFormGroup;

	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService,
		public userService: UserService,
		private router: Router,
		private eventService: EventService,
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
	) {}

	async ngOnInit() {
		const params = await firstValueFrom(this.activatedRoute.queryParams);
		this.initForm(params);
		await this.loadEvents()
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	setView(view: CalendarView) {
        this.calendarView = view;
		this.dataLoaded = false;
	}

	resetCalendar() {
		this.events = [];
		this.dataLoaded = false;
	}

	setPeriod(event: { period: {start: Date, end: Date} }): void {
		this.currentPeriod = event.period;
		if (!this.dataLoaded) {
			this.loadEvents();
		}
	}

	eventClicked(evt: any) {
		const correspondingEvent = this.calendarData.events.find(e => e.id === evt.event.id);
		if (confirm(CONSTANTS.SWITCH_BOOKING)) {
			this.router.navigateByUrl('dashboard/demandes/'+correspondingEvent.bookingId);
		}
	}

	async loadEvents() {
		this.dataLoaded = true;
		if (!!this.form) {
			this.calendarData =
				await firstValueFrom(
					this.eventService.getEventsByPeriod(
						this.currentPeriod.start,
						this.currentPeriod.end,
						this.form.get('zoneId').value
					)
				)
			this.events = this.calendarData.events.map(event => event.calendarEvent);
			this.setColorCells();
		}
	}

	private initForm(params) {
		this.form = this.formBuilder.group({
			zoneId: [params.zoneId || 0]
		})
	}

	private getTemporalVariable(date: Date): TemporalVariable {
		return this.calendarData?.temporalVariables.find(tv => {
			return (moment(date).clone().isSameOrAfter(tv.startAt) && moment(date).clone().isBefore(tv.endAt))
				|| tv.isCurrent
		})
	}

	private setColorCells() {
		const segments = document.getElementsByClassName('cal-hour-segment') as HTMLCollectionOf<HTMLDivElement>
		for (var i = 0; i < segments.length; i++) {
			const date = new Date(segments[i].dataset.date)
			if (this.isRed(date)) {
				segments[i].classList.add('red');
			} else if (this.isOrange(date)){
				segments[i].classList.add('orange');
			}
		}
	}

	private isOrange(date: Date) {
		const tv = this.getTemporalVariable(date);
		return !!tv ? tv.isOrange(date): false;
	}

	private isRed(date: Date) {
		const tv = this.getTemporalVariable(date);
		return !!tv ? tv.isRed(date): false;
	}

}
