<section class="container-fluid">
	<div class="header">
		<h1>Tableau de bord</h1>

		<form class="btn-group" [formGroup]="form" *ngIf="form">

			<button class="button is-primary" (click)="exportReport()">
				<mat-icon> bar_chart</mat-icon>
				<span>Exporter rapport CA</span>
			</button>

			<div class="custom-field" style="max-width: 280px; width: 280px;">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Zone géographique</mat-label>
					<mat-select formControlName="zoneId" (selectionChange)="zoneChanged()">
						<mat-option [value]="0">Toutes les zones</mat-option>
						<mat-option *ngFor="let zone of userService.allZones()" [value]="zone.id">
							{{zone.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>A partir de</mat-label>
					<input matInput required
						formControlName="startAt"
						[matDatepicker]="startPicker"
						placeholder="Date"
						(dateChange)="reloadContent()"
						[max]="form.get('endAt').value"
						[min]="START_LIMIT"
					>
					<mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
					<mat-datepicker #startPicker></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Jusqu'à</mat-label>
					<input matInput required
						formControlName="endAt"
						[matDatepicker]="endPicker"
						placeholder="Date"
						(dateChange)="reloadContent()"
						[max]="today"
						[min]="form.get('startAt').value"
					>
					<mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
					<mat-datepicker #endPicker></mat-datepicker>
				</mat-form-field>
			</div>
		</form>
	</div>

	<div class="columns" id="main-frame" *ngIf="stats">
		<div class="column is-6">
				<div class="columns">
				<div class="column is-3">
					<div class="box">
						<h5>{{stats.totalContractsNumber}} Devis réalisés</h5>
						<h4>{{stats.totalContractsAmount}} <span style="font-size: 0.75rem;">HT</span></h4>
					</div>
				</div>
				<div class="column is-3" >
					<div class="box green">
						<h5>{{stats.acceptedContractsNumber}} Devis acceptés </h5>
						<h4>{{stats.acceptedContractsAmount}} <span style="font-size: 0.75rem;">HT</span></h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box blue">
						<h5>{{stats.awaitingContractsNumber}} Devis en attente</h5>
						<h4>{{stats.awaitingContractsAmount}} <span style="font-size: 0.75rem;">HT</span></h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box red">
						<h5>{{stats.rejectedContractsNumber}} Devis refusés</h5>
						<h4>{{stats.rejectedContractsAmount}} <span style="font-size: 0.75rem;">HT</span></h4>
					</div>
				</div>

			</div>
			<div class="columns">
				<div class="column is-3">
					<div class="box">
						<h5>CA à date</h5>
						<h4>{{stats.realCa}} <span style="font-size: 0.75rem;">HT encaissé</span></h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box">
						<h5>TVA Collectée à date</h5>
						<h4>{{stats.vat}} <span style="font-size: 0.75rem;">encaissé</span></h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box clickable" (click)="goInvoicePage()">
						<h5>{{stats.awaitingInvoicesNumber}} facture<span *ngIf="stats.awaitingInvoicesNumber > 1">s</span>&nbsp;<span style="font-size: 0.75rem;">en att. de règlement</span></h5>
						<h4>{{stats.awaitingInvoicesAmount}} HT</h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box">
						<h5>Taux de transformation</h5>
						<h4>
							{{stats.closingRate}}
							<!-- <mat-icon
								style="margin-left:6px; margin-top: 2px; width: 12px !important; height: 12px !important; font-size: 12px !important"
								tooltipStr="Un devis est considéré comme non transformé s'il n'est pas accepté après 30 jours à compter de la date d'envoi">
								help_outline
							</mat-icon> -->
						</h4>
					</div>
				</div>
			</div>
			<div class="columns">
				<div class="column">
					<div class="box" style="height: 36dvh">
						<h5 style="margin: 1rem 0 2rem 0.5rem">Progression du CA HT</h5>
						<canvas #chart style="width: 100%; padding-bottom: 62px;"></canvas>
					</div>
				</div>
			</div>

		</div>
		<div class="column">
			<div class="box">
				<app-table-creator
					[config]="REGION_TABLE_CONFIG"
					[dataSource]="stats.regionStats"
				></app-table-creator>
			</div>
		</div>
	</div>

</section>
