import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ROLES } from '../../../enums/roles.enum';
import { Router } from '@angular/router';
import { AppService } from '../../../services/app.service';
import { BookingService } from '../../../services/booking.service';
import { UserService } from '../../../services/user.service';
import { CONSTANTS } from 'src/constants';
import { HelperService } from '../../../services/helper.service';
import moment from 'moment';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: false
})
export class MenuComponent {

	readonly window = window;
	readonly ROLES = ROLES;

	constructor(
		public authService: AuthService,
		public appService: AppService,
		public router: Router,
		public bookingService: BookingService,
		public userService: UserService,
		private helper: HelperService
	) { }

	logout() {
		if (confirm(CONSTANTS.YOU_SURE)) {
			this.authService.logout();
			this.appService.menuIsOpen.set(false);
		}
	}

	goStats() {
		this.appService.menuIsOpen.set(false);
		const params = {
			startAt: new Date().getFullYear().toString() + '-01-01',
			endAt: moment().format(CONSTANTS.DATE_TRANSACTION_FORMAT)
		}
		this.router.navigate(['dashboard', 'statistiques'], {queryParams: params})
	}

	goDemandes() {
		this.router.navigate([
			'dashboard',
			this.userService.currentUser().role === ROLES.CLIENT ? 'mes-demandes' : 'demandes'
		])
	}
}
