import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { BookingMinimalDto } from 'src/app/modules/shared/dtos/booking/booking-minimal.dto';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { BOOKING_STATUS, BOOKING_STATUS_OBJ_CLIENT } from '../../../shared/enums/booking-status.enum';

@Component({
    selector: 'app-mes-demandes',
    templateUrl: './mes-demandes.component.html',
    styleUrl: './mes-demandes.component.scss',
    standalone: false
})

export class MesDemandesComponent implements OnInit, OnDestroy {

	readonly ROLES = ROLES;
	// readonly BOOKING_STATUS = BOOKING_STATUS;
	readonly BOOKING_STATUS_OBJ = BOOKING_STATUS_OBJ_CLIENT;
	readonly Object = Object;

	bookingGroups: {date: string, collec: BookingMinimalDto[]}[] = [];
	private subscriptions: Subscription[] = [];

	constructor(
		public activatedRoute: ActivatedRoute,
		public helperService: HelperService,
		public authService: AuthService,
		public userService: UserService,
		public router: Router,
		public appService: AppService,
		public helper: HelperService
	) {	}

	ngOnInit() {
		this.subscriptions.push(
			this.activatedRoute.data.subscribe(data => {
				const bookingGroups = _.groupBy(
					data.bookingsPage.content,
					(booking: BookingMinimalDto) => this.helper.capitalize(moment(booking.date).locale('fr').format("MMMM YYYY"))
				)
				this.bookingGroups = Object.keys(bookingGroups).map((key) => {
					return {
						date: key,
						collec: bookingGroups[key]
					}
				})
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

}
