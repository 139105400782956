import { CommercialProposalDto } from './../../../shared/dtos/commercial-proposal/commercial-proposal.dto';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { BookingDto } from 'src/app/modules/shared/dtos/booking/booking.dto';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { CLIENT_TABS, TABS } from './tabs';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { BOOKING_STATUS_OBJ, BOOKING_STATUS, BOOKING_STATUS_OBJ_CLIENT } from 'src/app/modules/shared/enums/booking-status.enum';
import { AuthService } from 'src/app/modules/shared/services/auth.service';


@Component({
    selector: 'app-detail-demande',
    templateUrl: './detail-demande.component.html',
    styleUrls: ['./detail-demande.component.scss'],
    standalone: false
})

export class DetailDemandeComponent implements OnInit, OnDestroy {


	readonly BOOKING_STATUS_OBJ = this.userService.currentUser().role === ROLES.CLIENT
		? BOOKING_STATUS_OBJ_CLIENT
		: BOOKING_STATUS_OBJ;

		BOOKING_STATUS_OPTIONS;

	readonly TABS = this.userService.currentUser().role === ROLES.CLIENT ? CLIENT_TABS : TABS;
	readonly ROLES = ROLES;

	booking: BookingDto;
	proposal: CommercialProposalDto;
	devis = [];
	isLocked = false;

	private subscriptions: Subscription[] = [];

	constructor(
		public authService: AuthService,
		public userService: UserService,
		public helper: HelperService,
		private activatedRoute: ActivatedRoute,
		private bookingService: BookingService,
		private router: Router
	) { }

	ngOnInit() {
		this.subscriptions.push(
			this.activatedRoute.data.subscribe(data => {
				this.booking = data.booking;
				this.proposal = data.proposal;
				this.BOOKING_STATUS_OPTIONS = Object.keys(BOOKING_STATUS_OBJ).map((key, index) => {
					return {
						order: BOOKING_STATUS_OBJ[key].order,
						label: BOOKING_STATUS_OBJ[key].label,
						disabled: BOOKING_STATUS_OBJ[key].disabled
							|| this.booking.status === BOOKING_STATUS.PAYEE
							|| this.userService.currentUser().role === 'EMPLOYE_FEDE'
								&& ( key !== BOOKING_STATUS.REFUSEE && key !== BOOKING_STATUS.NON_TRAITEE)
								&& !this.bookingService.isDevisValide(this.booking)
							|| !this.bookingService.bookingZoneIsAllowed(this.booking),
						value: key
					}
				}).sort((optionA, optionB) => optionA.order > optionB.order ? 1 : -1)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	getMessage(): string {
		return this.booking.message.replace("/\n/g", "<BR/>")
	}

	async updateBookingStatus() {
		await firstValueFrom(
			this.bookingService.updateBookingStatus(
				this.booking.status as BOOKING_STATUS,
				this.booking.id
			)
		)
		this.router.navigate([])
	}
}
