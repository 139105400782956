import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription, debounceTime } from 'rxjs';
import { PageResponse } from 'src/app/modules/shared/dtos/page-response.dto';
import { UserMinimalDto } from 'src/app/modules/shared/dtos/user/user.minimal.dto';
import { ROLES, getRoleLitteral } from 'src/app/modules/shared/enums/roles.enum';
import { UserRequestParams } from 'src/app/modules/shared/models/request.params';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { UtilisateurModal } from './utilisateur-modal/utilisateur.modal';
import { CONSTANTS } from 'src/constants';

@Component({
    selector: 'app-utilisateurs',
    templateUrl: './utilisateurs.component.html',
    styleUrls: ['./utilisateurs.component.scss'],
    standalone: false
})


export class UtilisateursComponent implements OnInit, OnDestroy {

	readonly PAGINATION_OPTIONS = CONSTANTS.PAGINATION_OPTIONS;

	form: FormGroup;
	pageResponse: PageResponse<UserMinimalDto>;
	ROLES = ROLES;
	getRoleLitteral = getRoleLitteral;

	private subscriptions: Subscription[] = [];
	private formWatchSub: Subscription[] = [];

	constructor(
		public userService: UserService,
		public helperService: HelperService,
		public router: Router,
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
	) {}

	ngOnInit() {
		this.subscriptions.push(this.activatedRoute.data.subscribe(data => this.pageResponse = data.users))
		this.subscriptions.push(this.activatedRoute.queryParams.subscribe(params => this.initForm(params as UserRequestParams)))
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
		this.stopWatchNameFields();
	}

	roleChanged() {
		this.form.get('role').patchValue(
			this.form.get('role').value === 0
				? null
				: this.form.get('role').value
		)
		this.reloadContent();
	}

	zoneChanged() {
		this.form.get('zoneId').patchValue(
			this.form.get('zoneId').value === 0
				? null
				: this.form.get('zoneId').value
		)
		this.form.get('regionId').patchValue(null);
		this.reloadContent();
	}

	regionChanged() {
		this.form.get('regionId').patchValue(
			this.form.get('regionId').value === 0
				? null
				: this.form.get('regionId').value
		)
		this.form.get('zoneId').patchValue(null)
		this.reloadContent();
	}

	archivedChanged() {
		this.form.get('archived').patchValue(
			this.form.get('archived').value === 0
				? null
				: this.form.get('archived').value
		)
		this.reloadContent();
	}

	pageChanged(event: PageEvent) {
		this.form.get('offset').patchValue(event.pageIndex);
		this.form.get('limit').patchValue(event.pageSize);
		this.reloadContent();
	}

	resetForm() {
		this.stopWatchNameFields();
		this.form.reset();
		this.initForm(null);
		this.reloadContent()
	}

	openUserModal(userId?: number) {
		this.dialog.open(UtilisateurModal, {
			data: {
				userId,
				saveCloseCallBack: () => this.reloadContent()
			}
		})
	}

	goClientPage(clientId: number, region: string) {
		const regionId = this.userService.currentUser().regionalCommittees.find(r => r.title === region).id;
		this.router.navigate(['dashboard', 'demandes'], {queryParams: { clientId, regionId }})
	}

	private initForm(params: UserRequestParams) {
		this.form = this.formBuilder.group({
			offset: [parseInt(params?.offset as unknown as string) || 0, [Validators.required]],
			limit: [parseInt(params?.limit as unknown as string) || 0, [Validators.required]],
			regionId: [parseInt(params?.regionId as unknown as string) || 0, []],
			zoneId: [parseInt(params.zoneId as unknown as string) || 0, []],
			role: [!params?.role || parseInt(params.role) === 0 ? 0 : params.role, []],
			name: [params?.name || null, []],
			companyName: [params?.companyName || null, []],
			archived: [_.isEmpty(params?.archived) || params?.archived.toString() === "0" ? 0 : params?.archived, []]
		})
		this.watchNameFields()
	}

	private watchNameFields() {
		this.formWatchSub.push(
			this.form.get('name').valueChanges.pipe(debounceTime(600)).subscribe(() => this.reloadContent()),
			this.form.get('companyName').valueChanges.pipe(debounceTime(600)).subscribe(() => this.reloadContent())
		)
	}

	private reloadContent() {
		if (this.form.valid) {
			this.router.navigate([], {
				queryParams: this.form.getRawValue(),
				onSameUrlNavigation: 'reload'
			})
		}
	}

	private stopWatchNameFields() {
		this.formWatchSub.forEach(s => s.unsubscribe());
	}
}
