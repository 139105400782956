
<header>
	<h1>Injection de prestations héritage</h1>
</header>

<section class="container-fluid">
	<form *ngIf="!!form && !!customers.length" [formGroup]="form">
		<!-- TOP -->
		<div class="bloc">
			<div class="title">
				<h2>Information générales du devis</h2>
			</div>
			<div class="columns is-multiline">
				<div class="column is-3">
					<mat-form-field subscriptSizing="dynamic">
						<mat-label>Zone géographique du client</mat-label>
						<mat-select formControlName="zoneId" (selectionChange)="zoneChanged()">
							<mat-option [value]="0">Toutes les zones</mat-option>
							<mat-option *ngFor="let zone of userService.currentUser().zones" [value]="zone.id">
								{{zone.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>

				</div>
				<div class="column is-5">
					<mat-form-field>
						<mat-label>Client</mat-label>
						<input matInput type="text" [matAutocomplete]="auto" formControlName="customerSearch">
						<mat-autocomplete
							#auto="matAutocomplete"
							[displayWith]="clientDisplayName"
							(optionSelected)="customerChanged($event)"
						>
							<mat-option *ngFor="let customer of filteredCustomers" [value]="customer">
								<div class="is-flex is-align-items-center">
									<mat-label style="flex:1">{{clientDisplayName(customer)}}</mat-label>
								</div>
							</mat-option>
							<mat-option [disabled]="true" *ngIf="filteredCustomers?.length === 0">
								Aucun résultat
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
				<div class="column is-2">
					<mat-form-field subscriptSizing="dynamic">
						<mat-label>Date du devis</mat-label>
						<input matInput required
							formControlName="dateDevis"
							[matDatepicker]="datepicker"
							placeholder="Date du devis"
						>
						<mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
						<mat-datepicker #datepicker></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="column is-2 centered-box">
					<mat-label>Appliquer la TVA</mat-label>
					<mat-checkbox formControlName="tva" id="tva"></mat-checkbox>
				</div>
			</div>
		</div>

		<!-- LINES -->
		<div class="bloc" formArrayName="lines">
			<div class="title">
				<h2>Prestations associées</h2>
				<button class="button is-primary is-small" (click)="addPrestation()">
					<mat-icon>add</mat-icon>
					<span>Ajouter une prestation</span>
				</button>
			</div>

			<ng-container *ngFor="let line of lines.controls; let i = index;">
				<div class="columns" [formGroupName]="i">
					<!-- ACTIVITY -->
					<div class="column is-3">
						<mat-form-field subscriptSizing="dynamic">
							<mat-label>Activité</mat-label>
							<mat-select formControlName="activiteId" (selectionChange)="loadActivityOffers(i)">
								<mat-option *ngFor="let activite of activites" [value]="activite.id">
									{{activite.title}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<!-- DATE -->
					<div class="column is-2">
						<mat-form-field subscriptSizing="dynamic">
							<mat-label>Date d'éxécution</mat-label>
							<input matInput required
								formControlName="dateExecution"
								[matDatepicker]="datepicker"
								placeholder="Date d'éxécution"
							>
							<mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
							<mat-datepicker #datepicker></mat-datepicker>
						</mat-form-field>
					</div>
					<!-- OFFRE -->
					<div class="column is-3">
						<mat-form-field subscriptSizing="dynamic">
							<mat-label>Offre</mat-label>
							<mat-select formControlName="offreId" (selectionChange)="offerChanged(i)">
								<mat-option *ngFor="let o of getOffersForIndex(i)" [value]="o.id">
									{{o.proposalDescription}} <span *ngIf="line.get('activiteId').value !== A_LA_CARTE">({{o.price}}€)</span>
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<!-- PRICE -->
					<div class="column is-1 centered-box">
						<!-- A LA CARTE -->
						<mat-form-field subscriptSizing="dynamic">
							<mat-label>Montant</mat-label>
							<input matInput type="number" formControlName="onDemandPrice" [min]="0">
						</mat-form-field>
					</div>
					<!-- DISCOUNT % -->
					<div class="column is-1">
						<mat-form-field subscriptSizing="dynamic">
							<mat-label>Remise %</mat-label>
							<input matInput type="number" formControlName="remise" [min]="0" [max]="100">
						</mat-form-field>
					</div>
					<!-- FINAL PRICE -->
					<div class="column is-1 centered-box">
						<div>Total prestation</div>
						<b>{{getLineTotalPrice(i).toFixed(2)}}€</b>
					</div>
					<!-- DEL -->
					<div class="column is-1" style="display:flex; align-items: center; justify-content: center;">
						<button class="button is-small is-light is-danger" tooltipStr="Retirer la prestation" (click)="lines.removeAt(i)">
							<mat-icon style="font-size: 14px;">delete</mat-icon>
						</button>
						<button class="button is-small is-primary" style="margin-left: 0.5rem;" tooltipStr="Doubler la prestation" (click)="double(i)">
							<mat-icon style="font-size: 14px;">plus_one</mat-icon>
						</button>
					</div>
				</div>
			</ng-container>
		</div>
	</form>
</section>

<footer *ngIf="!!form">

	<ng-container *ngIf="form.get('tva').value">
		<h3>Total HT: {{getTotalHT().toFixed(2)}}€</h3>
		<h3 >Montant TVA: {{getVat().toFixed(2)}}€</h3>
	</ng-container>

	<h1>Grand total: {{getTotalTTC().toFixed(2)}}€</h1>

	<button class="button is-primary" (click)="save()" [disabled]="!form.valid || lines.length === 0">
		Enregistrer la prestation
	</button>
</footer>
