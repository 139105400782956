import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { toObservable } from '@angular/core/rxjs-interop';


@Injectable({
    providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
    requests: HttpRequest<any>[] = [];
    appIsLoadingState: boolean = false;

    constructor(
		private appService: AppService
	) {
		toObservable(this.appService.isLoading).subscribe(isLoading => this.appIsLoadingState = isLoading)
	}

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (this.appIsLoadingState && this.requests.length === 0) {
			this.appService.isLoading.set(false)
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		this.appService.isLoading.set(true);
        this.requests.push(req);

        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
