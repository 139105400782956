import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import moment from 'moment';


export function dateNoFutureValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!!control.value) {
            return moment(control.value).isBefore(moment()) ? null : {dateIsFuture: {value: control.value}};
        } else {
           return null;
        }
    };
}
