import { Component } from '@angular/core';

@Component({
    selector: 'app-slot-booking-success',
    templateUrl: './slot-booking-success.component.html',
    styleUrl: './slot-booking-success.component.scss',
    standalone: false
})
export class SlotBookingSuccessComponent {

}
