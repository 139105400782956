
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseThemeDto, ThemeDto } from '../dtos/activity/theme.dto';
import { ActivityDto, ActivityCreateDto, ActivityUpdateDto } from '../dtos/activity/activity.dto';
import { CreateOfferDto } from '../dtos/offer/create-offer.dto';
import { OfferDto } from '../dtos/offer/offer.dto';

@Injectable({
	providedIn: 'root'
})
export class ActivityService {

	constructor(
		private http: HttpClient
	) { }

	getThemes(regionId?: number): Observable<ThemeDto[]> {
		let params = new HttpParams();
		if (regionId)
			params = params.append('regionId', regionId);
		return this.http.get<ThemeDto[]>(`${environment.urlApi}/themes`, { params });
	}

	getThemesBasics(): Observable<BaseThemeDto[]> {
		return this.http.get<BaseThemeDto[]>(`${environment.urlApi}/themes/basics`);
	}

	getActivityById(id: number, regionId?: number): Observable<ActivityDto> {
		if (regionId) {
			return this.http.get<ActivityDto>(`${environment.urlApi}/activities/${id}?region=${regionId}`);
		}
		return this.http.get<ActivityDto>(`${environment.urlApi}/activities/${id}`);
	}

	getActivityOffers(activityId: number): Observable<OfferDto[]> {
		return this.http.get<OfferDto[]>(`${environment.urlApi}/activities/${activityId}/offers`)
	}

	createActivity(dto: ActivityCreateDto): Observable<ActivityDto> {
		return this.http.post<ActivityDto>(`${environment.urlApi}/activities`, dto);
	}

	updateActivity(dto: ActivityUpdateDto): Observable<ActivityDto> {
		return this.http.put<ActivityDto>(`${environment.urlApi}/activities/${dto.id}`, dto);
	}

	updateActivityText(
		id: number,
		activity: ActivityDto
	): Observable<ActivityDto> {
		return this.http.put<ActivityDto>(`${environment.urlApi}/activities/${id}/presentation`, {
			presentation: activity.presentation,
			objectives: activity.objectives,
			modalities: activity.modalities
		});
	}

	deleteActivity(id: number): Observable<any> {
		return this.http.delete(`${environment.urlApi}/activities/${id}`);
	}

	deleteOffer(id: number): Observable<any> {
		return this.http.delete(`${environment.urlApi}/offers/${id}`);
	}

	createOffer(offer: CreateOfferDto): Observable<any> {
		return this.http.post(`${environment.urlApi}/offers`, offer);
	}

	updateOffer(offer: OfferDto): Observable<any> {
		return this.http.put(`${environment.urlApi}/offers`, offer);
	}

	getActivitesByComiteRegionalId(crId: number): Observable<ActivityDto[]> {
		return this.http.get<ActivityDto[]>(`${environment.urlApi}/par-comite-regional/${crId}`);
	}

	addActivityToCommittee(activityId: number, committeeId: number): Observable<any> {
		return this.http.post(
			`${environment.urlApi}/activities/${activityId}/add-to-committee/${committeeId}`,
			null
		);
	}

	removeActivityFromCommittee(activityId: number, committeeId: number): Observable<any> {
		return this.http.delete(
			`${environment.urlApi}/activities/${activityId}/remove-from-committee/${committeeId}`,
		);
	}
}
