import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-erreur',
    templateUrl: './erreur.component.html',
    styleUrls: ['./erreur.component.scss'],
    standalone: false
})
export class ErreurComponent implements OnInit {

	constructor() {}

	ngOnInit(): void {}
}
