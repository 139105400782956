<div class="columns is-centered">
	<div class="column" *ngFor="let day of days">
		<h4 style="text-align: center;">{{day.dateTitle}}</h4>

		<mwl-calendar-day-view  #dayComponent
			[viewDate]="day.date"
			[locale]="CONSTANTS.CALENDAR_LOCALE"
			[dayStartHour]="day.startHour"
			[dayEndHour]="day.endHour"
			[hourSegmentHeight]="CONSTANTS.CALENDAR_SEGMENT_HEIGHT * 2"
			[events]="day.slots"
			[refresh]="refresh"
			(eventClicked)="eventClicked($event)">
		</mwl-calendar-day-view>
	</div>
</div>
