import { firstValueFrom } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { matchValidator } from 'src/app/modules/shared/validators/passwords-match.validator';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';


@Component({
    selector: 'app-reinitialiser-mot-de-passe',
    templateUrl: './reinitialiser-mot-de-passe.component.html',
    styleUrls: ['./reinitialiser-mot-de-passe.component.scss'],
    standalone: false
})
export class ReinitialiserMotDePasseComponent implements OnInit {

	form: FormGroup;
	token: string;

	MODE: 'PUBLIC' | 'DASHBOARD';

	constructor(
		private fb: FormBuilder,
		private authService: AuthService,
		private userService: UserService,
		private route: ActivatedRoute,
		private notificationService: NotificationService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.MODE = this.route.snapshot.queryParamMap.get('token') && !this.userService.currentUser()
			? 'PUBLIC'
			: 'DASHBOARD';

		if (this.MODE === 'PUBLIC') {
			this.validateToken();
			this.token = this.route.snapshot.queryParamMap.get('token');
		} else {
			this.initForm();
		}
	}

	private async validateToken() {
		if ((await firstValueFrom(this.authService.validateEmailToken(this.route.snapshot.queryParamMap.get('token')))).valid) {
			this.initForm();
		}
	}

	private initForm() {
		this.form = this.fb.group({
			password: ['', [Validators.required, Validators.minLength(7), matchValidator('passwordTwo', true)]],
			passwordTwo: ['', [Validators.required, Validators.minLength(7), matchValidator('password')]]
		});
	}

	onSubmit() {
		if (this.MODE === 'PUBLIC') {
			this.updateForgottenPw();
		} else {
			this.updatePw();
		}
	}

	private updateForgottenPw() {
		if (this.token) {
			this.authService.resetPassword({
				token: this.token,
				newPassword: this.form.get('password').value,
			}).subscribe(
				() => {
					this.notificationService.showSuccess('Mot de passe réinitialisé', 'Succès !')
				},
			);
		}
	}

	private async updatePw() {
		await firstValueFrom(this.userService.changePassword(this.form.get('password').value));
		this.notificationService.showSuccess('Mot de passe modifié', 'Succès !')
		this.router.navigate(['dashboard']);
	}
}
