import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarDayViewComponent, CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewComponent, CalendarView, CalendarWeekViewComponent } from 'angular-calendar';
import moment from 'moment';
import { Subject, Subscription, filter, firstValueFrom } from 'rxjs';
import { BookingDto } from 'src/app/modules/shared/dtos/booking/booking.dto';
import { CalendarData } from 'src/app/modules/shared/dtos/calendar.dto';
import { EventMinimalDto, ProvisionDto } from 'src/app/modules/shared/dtos/event/event.dto';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { TemporalVariable } from 'src/app/modules/shared/models/temporal-variable';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { CONSTANTS } from 'src/constants';
import { EventCalendarModalComponent } from '../../../modals/event-calendar-modal/event-calendar-modal.component';
import { BOOKING_STATUS, BOOKING_STATUS_OBJ } from './../../../../shared/enums/booking-status.enum';

@Component({
    selector: 'app-organiser',
    templateUrl: './organiser.component.html',
    styleUrl: './organiser.component.scss',
    standalone: false
})
export class OrganiserComponent implements OnInit, OnDestroy {

	readonly CONSTANTS = CONSTANTS;
	readonly CalendarView = CalendarView;

    @ViewChild('monthComponent') monthComponent: CalendarMonthViewComponent;
    @ViewChild('weekComponent') weekComponent: CalendarWeekViewComponent;
    @ViewChild('dayComponent') dayComponent: CalendarDayViewComponent;

    view: CalendarView = CalendarView.Week;
    viewDate: Date = new Date();
    refresh: Subject<any> = new Subject();
	events: CalendarEvent[] = [];
	activeDayIsOpen = false;
	booking: BookingDto;
	currentPeriod: { start: Date, end: Date };
	dataLoaded = false;
	currentRegionId: number;
	calendarData: CalendarData;

	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService,
		public bookingService: BookingService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog,
		private eventService: EventService
	) {}

	ngOnInit(): void {
		this.getBookingParams();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	setView(view: CalendarView) {
        this.view = view;
		this.dataLoaded = false;
	}

	navigateCalendar() {
		this.events = [];
		this.dataLoaded = false;
	}

	setPeriod(event: { period: {start: Date, end: Date} }): void {
		this.currentPeriod = event.period;
		if (!this.dataLoaded) {
			this.loadEvents();
		}
	}

	eventTimesChanged(calendarEvent: CalendarEventTimesChangedEvent) {
		if (this.bookingService.isBookingLocked(this.booking)) {
			alert(this.bookingService.whyBookingIsLocked(this.booking));
		} else {
			this.updateCalendar(calendarEvent);
			this.updateOnServer(calendarEvent);
		}
	}

	async eventClicked(evt: any) {
		const correspondingEvent = this.calendarData.events.find(e => e.id === evt.event.id)
		if (correspondingEvent.bookingId !== this.booking.id) {
			if (confirm(CONSTANTS.SWITCH_BOOKING)) {
				this.router.navigateByUrl('dashboard/demandes/'+correspondingEvent.bookingId);
				this.getBookingParams();
				this.dataLoaded = false;
				this.refresh.next(null);
			}
		} else if (correspondingEvent.activityType === ACTIVITY_TYPES.INDIVIDUELLE) {
			this.dialog.open(EventCalendarModalComponent, {
				data: {
					eventId: correspondingEvent.id
				}
			})
		}
	}

	async resetCompletely() {
		if (confirm(CONSTANTS.YOU_SURE + '\n' + 'Les dates et offres de la demande seront restaurées tel que le client les a formulé.')) {
			await firstValueFrom(this.bookingService.reset(this.booking.id));
			this.dataLoaded = false;
			this.loadEvents();
		}
	}

	private async updateCalendar(calendarEvent: CalendarEventTimesChangedEvent) {
		const eventId = calendarEvent.event.id;
		const correspondingEvent = this.events.find(e => e.id === eventId);
		correspondingEvent.start = calendarEvent.newStart;
		correspondingEvent.end = calendarEvent.newEnd;
		this.refresh.next(null);
	}

	private async updateOnServer(calendarEvent: CalendarEventTimesChangedEvent) {
		const eventId = calendarEvent.event.id;
		const correspondingEvent = this.calendarData.events.find(se => se.id === eventId);
		correspondingEvent.date = calendarEvent.newStart;
		if (correspondingEvent.activityType === ACTIVITY_TYPES.AUTRE) {
			correspondingEvent.eventDuration = moment(calendarEvent.newEnd).diff(calendarEvent.newStart, 'minutes');
		}
		await firstValueFrom(this.eventService.updateEvent(correspondingEvent.dto));
	}

	async loadEvents() {
		this.dataLoaded = true;
		this.calendarData = (await firstValueFrom(
			this.eventService.getEventsByPeriod(
				this.currentPeriod.start,
				this.currentPeriod.end,
				this.booking.user.department.zoneId
			)
		))
		this.events = this.calendarData.events.map(event => event.getCalendarEvent(this.booking.id));
		this.setColorCells();
	}

	private getBookingParams(): void {
		this.subscriptions.push(
			this.activatedRoute.parent.data.pipe(
				filter(data => !this.booking || data.booking.id !== this.booking.id)
			).subscribe(data => {
				this.booking = data.booking as BookingDto;
				const sortedEvents = this.booking.provisions.map(p => p.events).flat().sort((a: EventMinimalDto, b: EventMinimalDto) => {
					return new Date(a.date).getTime() - new Date(b.date).getTime();
				})
				this.viewDate = !!sortedEvents[0] ? new Date(sortedEvents[0].date) : new Date();
				this.navigateCalendar();
			})
		)
	}

	private getTV(date: Date): TemporalVariable {
		return this.calendarData?.temporalVariables.find(tv => {
			return (moment(date).clone().isSameOrAfter(tv.startAt) && moment(date).clone().isBefore(tv.endAt))
				|| tv.isCurrent
		})
	}

	private setColorCells() {
		const segments = document.getElementsByClassName('cal-hour-segment') as HTMLCollectionOf<HTMLDivElement>
		for (var i = 0; i < segments.length; i++) {
			const date = new Date(segments[i].dataset.date)
			if (this.isRed(date)) {
				segments[i].classList.add('red');
			} else if (this.isOrange(date)){
				segments[i].classList.add('orange');
			}
		}
	}

	private isOrange(date: Date) {
		const tv = this.getTV(date);
		return !!tv ? tv.isOrange(date): false;
	}

	private isRed(date: Date) {
		const tv = this.getTV(date);
		return !!tv ? tv.isRed(date): false;
	}
}
