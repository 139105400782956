import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DynamicTableConfig } from '../dynamic-table-config';



export type SortDirection = 'asc' | 'desc';

export interface DynamicTableSort {
	name: string;
	direction: SortDirection;
	sortOperationName?: string;
}


@Component({
    selector: 'app-table-creator',
    templateUrl: './table-creator.component.html',
    styleUrls: ['./table-creator.component.scss'],
    standalone: false
})

export class TableCreatorComponent implements OnInit, OnDestroy {

	@ViewChild('thead') thead: ElementRef<HTMLTableSectionElement>;
	@ViewChild('tbody') tbody: ElementRef<HTMLTableSectionElement>;

	currentSort: DynamicTableSort;

    @Input() dataSource: Array<any>;
	@Input() height: string;
    @Input() config: DynamicTableConfig;

	bodyHeight = "100px";
	isScrolling = false;

	private subscriptions: Subscription[] = [];

	constructor() {  }

    ngOnInit() {
		if (this.config.defaultSort) {
			this.currentSort = this.config.defaultSort;
			this.sortBy(this.currentSort.name, this.currentSort.direction)
		} else {
			this.currentSort = {
				name: this.config.columns[0].fieldName,
				direction: 'asc'
			}
		}
    }

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	sortBy(name: string, direction?: SortDirection): void {
		if (!!direction) {
			this.currentSort = { name, direction };
		} else {
			if (this.currentSort.name != name) {
				this.currentSort = {
					name,
					direction: 'asc'
				}
			} else {
				this.currentSort = {
					name,
					direction: this.currentSort.direction === 'asc' ? 'desc' : 'asc'
				}
			}
		}
		this.applySort()
	}

	applySort(): void {
		this.dataSource = this.dataSource.sort(
			this.compare(
				this.currentSort.name,
				this.currentSort.direction === 'desc' ? -1 : 1,
				(item: string) => item.toString().toLowerCase()
			)
		);
	}


	private compare(propertyName: string, reverseMath: number, operation?: any) {
        const key = operation ? (x) => operation(x[propertyName]) : (x) => x[propertyName];

        return (a, b) => {
            const valA: any = key(a);
            const valB: any = key(b);
            return reverseMath * (+(valA > valB) - +(valB > valA));
        };
    }
}
