import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { Subscription, firstValueFrom } from 'rxjs';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { DOCUMENT_UPLOAD_TYPES, DOCUMENT_UPLOAD_TYPES_OBJ } from 'src/app/modules/shared/dtos/document/document-upload.dto';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { DocumentService } from 'src/app/modules/shared/services/document.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UploadService } from 'src/app/modules/shared/services/upload.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { CONSTANTS } from 'src/constants';
import { DocumentDto } from 'src/app/modules/shared/dtos/document/document.dto';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { BookingDto } from 'src/app/modules/shared/dtos/booking/booking.dto';
import { da } from 'date-fns/locale';

@Component({
    selector: 'app-documents-tab',
    templateUrl: './documents-tab.component.html',
    styleUrl: './documents-tab.component.scss',
    standalone: false
})

export class DocumentsTabComponent implements OnInit, OnDestroy {

	@ViewChild('fileInput') fileInput: ElementRef<HTMLButtonElement>;

	readonly ROLES = ROLES;
	readonly DOCUMENT_UPLOAD_TYPES = DOCUMENT_UPLOAD_TYPES;
	readonly DOCUMENT_UPLOAD_TYPES_OBJ = DOCUMENT_UPLOAD_TYPES_OBJ;
	readonly DOCUMENT_UPLOAD_OPTIONS = Object.keys(DOCUMENT_UPLOAD_TYPES_OBJ).map((key, index) => {
		return {
			order: DOCUMENT_UPLOAD_TYPES_OBJ[key].order,
			label: DOCUMENT_UPLOAD_TYPES_OBJ[key].label,
			value: key
		}
	}).sort((optionA, optionB) => optionA.order > optionB.order ? 1 : -1)

	formOpen = false;
	form: FormGroup;
	booking: BookingDto;
	documents: DocumentDto[] = []

	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService,
		public userService: UserService,
		public bookingService: BookingService,
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private uploadService: UploadService,
		private documentService: DocumentService,
		private appService: AppService
	) {}


	ngOnInit(): void {
		this.getBooking();
		this.getDocuments();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	selectFile($event: Event) {
		const file: File = (<HTMLInputElement>$event.target).files[0];
		this.initForm(file);
		this.formOpen = true;
	}

	documentTypeChanged(): void {
		if (this.form.get('fileType').value === DOCUMENT_UPLOAD_TYPES.BON_DE_COMMANDE) {
			this.form.get('purchaseOrder').addValidators(Validators.required);
			this.form.get('purchaseOrder').markAsTouched();
		} else {
			this.form.get('purchaseOrder').removeValidators(Validators.required);
		}
		this.form.get('purchaseOrder').updateValueAndValidity();
	}

	getDocumentTypeLabel(docType: DOCUMENT_UPLOAD_TYPES): string {
		return this.DOCUMENT_UPLOAD_OPTIONS.find(option => option.value === docType).label;
	}

	async download(document: DocumentDto) {
		const doc = await firstValueFrom(this.documentService.downloadDocument(document.downloadUrl));
		saveAs(doc.body, `${document.fileName}.${document.extension}`);
	}

	async uploadDocument() {
		await firstValueFrom(this.uploadService.uploadFile(this.form.getRawValue()));
		this.formOpen = false;
		this.appService.reloadPage();
	}

	async deleteDoc(docId: number) {
		if (confirm(CONSTANTS.YOU_SURE)) {
			await firstValueFrom(this.documentService.deleteDocument(docId));
			this.appService.reloadPage();
		}
	}

	private getBooking(): void {
		this.subscriptions.push(
			this.activatedRoute.parent.data.subscribe(data => this.booking = data.booking)
		)
	}

	private getDocuments(): void {
		this.subscriptions.push(
			this.activatedRoute.data.subscribe(data => this.documents = data.documents)
		)
	}

	private initForm(file: File): void {
		const nameSplit = file.name.split('.');
		const extension = nameSplit[nameSplit.length-1];
		const fileName = nameSplit.splice(0, nameSplit.length-1).join("");

		this.form = this.formBuilder.group({
			file: [file, [Validators.required]],
			fileName: [fileName, [Validators.required]],
			fileType: [null, [Validators.required]],
			extension: [extension, [Validators.required]],
			bookingId: [this.booking.id, [Validators.required]],
			comment: [],
			purchaseOrder: []
		})
	}

}
