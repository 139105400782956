<div id="modal-container">

	<h1 *ngIf="form && mode === 'EDIT'">{{form.get('firstName').value}} {{form.get('lastName').value}}</h1>
	<h1 *ngIf="form && mode === 'NEW'">Nouveau compte utilisateur</h1>

	<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
		<h4>Rôle de l'utilisateur</h4>
		<div class="columns is-marginless">
			<div class="column is-one-third">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Rôle</mat-label>
					<mat-select formControlName="role" (selectionChange)="roleChanged()">
						<mat-option [value]="ROLES.CLIENT">Client</mat-option>
						<mat-option [value]="ROLES.EMPLOYE_FEDE">Employé fédéral</mat-option>
						<mat-option [value]="ROLES.ADMIN_FEDE">Administrateur</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column is-one-third" *ngIf="form.get('role').value === ROLES.CLIENT">
				<mat-form-field appearance="fill" subscriptSizing="dynamic" >
					<mat-label>Région de l'entreprise</mat-label>
					<mat-select formControlName="regionId" (selectionChange)="regionChanged()">
						<mat-option
							*ngFor="let region of comiteRegionals"
							[value]="region.id">
							{{region.title}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column is-one-third" *ngIf="form.get('role').value === ROLES.CLIENT">
				<mat-form-field appearance="fill" subscriptSizing="dynamic" >
					<mat-label>Département de l'entreprise</mat-label>
					<mat-select formControlName="departmentId">
						<mat-option
							*ngFor="let department of departments"
							[value]="department.id">
							{{department.name}} ({{department.number}})
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column is-one-third" *ngIf="form.get('role').value === ROLES.EMPLOYE_FEDE" style="text-align: left;">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Zone géographique de rattachement</mat-label>
					<mat-select formControlName="zoneIds" multiple>
						<mat-option
							*ngFor="let zone of userService.allZones()"
							[disabled]="userService.currentUser().role !== ROLES.ADMIN_FEDE"
							[value]="zone.id"
						>
							{{zone.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column" *ngIf="form.get('role').value !== ROLES.CLIENT" style="text-align: left;">
				<div>
					<mat-checkbox formControlName="logisticTeam"></mat-checkbox>
					<span>Logisticien</span>
				</div>
				<div class="explain">Les logisticiens reçoivent une notification par email lorsqu'un devis est déclaré comme accepté par le client.</div>
			</div>
		</div>

		<h4>Informations</h4>
		<div class="columns is-marginless" *ngIf="form.get('role').value === ROLES.CLIENT">
			<div class="column is-one-third">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Nom de l'entreprise</mat-label>
					<input matInput formControlName="companyName" />
				</mat-form-field>
			</div>
			<div class="column is-one-third">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Strate client</mat-label>
					<mat-select formControlName="strate">
						<mat-option *ngFor="let strate of STRATE_LAPOSTE" [value]="strate">{{strate}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column is-one-third">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Fonction dans l'entreprise</mat-label>
					<mat-select formControlName="function">
						<mat-option *ngFor="let job of JOBS" [value]="job">{{job}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="columns is-marginless">
			<div class="column is-half">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Nom de famille</mat-label>
					<input matInput formControlName="lastName" #lastname (input)="form.get('lastName').patchValue(form.get('lastName').value.toUpperCase())" />
					<mat-error *ngIf="form.controls['lastName'].hasError('required')">
						Le champ est requis.
					</mat-error>
				</mat-form-field>
			</div>
			<div class="column is-half">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Prénom</mat-label>
					<input matInput formControlName="firstName" />
					<mat-error *ngIf="form.controls['firstName'].hasError('required')">
						Le champ est requis.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="columns is-marginless">
			<div class="column is-half">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Email (identifiant)</mat-label>
					<input matInput formControlName="email" type="email"/>
					<mat-error *ngIf="form.controls['email'].hasError('exists')">Ce compte existe déjà.</mat-error>
				</mat-form-field>
			</div>
			<div class="column is-half">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Numéro de téléphone</mat-label>
					<input matInput formControlName="phone" maxlength="14" mask="00.00.00.00.00" />
					<mat-error *ngIf="form.controls['phone'].hasError('required')">
						Le champ est requis.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="columns is-marginless" *ngIf="form.get('role').value === ROLES.CLIENT">
			<div class="column is-half">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Adresse de l'entreprise</mat-label>
					<input matInput formControlName="address" />
				</mat-form-field>
			</div>
			<div class="column is-half">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Complément d'adresse</mat-label>
					<input matInput formControlName="addressLineTwo" />
				</mat-form-field>
			</div>
		</div>

		<div class="columns is-marginless" *ngIf="form.get('role').value === ROLES.CLIENT">
			<div class="column is-2">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Code postal</mat-label>
					<input matInput formControlName="zipCode" mask="00000" (keydown)="codePostalChanged()" />
					<mat-error *ngIf="form.controls['zipCode'].hasError('required')">
						Requis.
					</mat-error>
				</mat-form-field>
			</div>
			<div class="column is-4">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Ville de l'entreprise</mat-label>
					<input matInput type="text" required formControlName="city" [matAutocomplete]="citySuggestions" #cityField>
					<mat-autocomplete #citySuggestions>
						<mat-option *ngFor="let option of communes" [value]="option.nom">{{ option.nom }}</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
			<div class="column">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Adresse email de facturation</mat-label>
					<input matInput formControlName="billingEmail" />
				</mat-form-field>
			</div>
		</div>

		<div id="activation" *ngIf="mode === 'EDIT'">
			<div class="status" [ngClass]="isArchived?.toString()">Ce compte est {{ isArchived ? 'désactivé' : "actif" }}</div>
			<button
				class="button is-inverted"
				type="button"
				[ngClass]="{'is-primary' : !isArchived, 'is-info': isArchived}"
				(click)="toggleArchived()"
				>{{isArchived ? "Réactiver ce compte" : "Désactiver ce compte "}}
			</button>
		</div>

		<div id="activation" *ngIf="mode === 'NEW'">
			<div class="status">Pour tous les nouveaux comptes, le mot de passe par défaut est<BR/><b>jmactiv</b></div>
		</div>


		<div id="buttons">
			<button class="button" (click)="close()">
				<span>Fermer</span>
			</button>
			<button class="button is-primary" type="submit" [disabled]="!form.valid">
				<span *ngIf="mode === 'EDIT'">Mettre à jour</span>
				<span *ngIf="mode === 'NEW'">Créer le compte</span>
			</button>
		</div>
	</form>
</div>
