import { Component, Input, OnInit, OnChanges } from '@angular/core';
import {ButtonFieldType} from '../../models/FieldConfig';

@Component({
    selector: 'app-button-field',
    templateUrl: './button-field.component.html',
    styleUrls: ['./button-field.component.css'],
    standalone: false
})
export class ButtonFieldComponent implements OnInit {

    @Input() objectValue: any;
    @Input() value;
    @Input() options: ButtonFieldType;
    @Input() param: any;
    @Input() margins: string;

    title: string;
    disabled: boolean;
    greyed: boolean;

    constructor() {
    }

    ngOnInit() {
        this.title = this.options.label ? this.options.label : this.value ? this.value === true ? '' : this.value : '';
        this.disabled = this.options.propertyDisable && this.objectValue && this.objectValue[this.options.propertyDisable] ? this.objectValue[this.options.propertyDisable] : false;
        this.greyed = this.options.propertyGrey && this.objectValue && this.objectValue[this.options.propertyGrey] ? this.objectValue[this.options.propertyGrey] : false;
    }

}
