<section class="container-fluid">
	<div class="header">
		<h1>Liste des utilisateurs</h1>

		<form class="btn-group" [formGroup]="form" *ngIf="form">

			<button class="button is-primary" (click)="openUserModal()">
				<mat-icon>person_add</mat-icon>
				<span>Créer un utilisateur</span>
			</button>

			<div class="custom-field" style="max-width: 280px; width: 280px;">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Zone géographique</mat-label>
					<mat-select formControlName="zoneId" (selectionChange)="zoneChanged()">
						<mat-option [value]="0">Toutes les zones</mat-option>
						<mat-option *ngFor="let zone of userService.allZones()" [value]="zone.id">
							{{zone.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Role</mat-label>
					<mat-select formControlName="role" (selectionChange)="roleChanged()">
						<mat-option [value]="0">Toutes les rôles</mat-option>
						<mat-option value="CLIENT">Client</mat-option>
						<mat-option value="EMPLOYE_FEDE">Employé fédéral</mat-option>
						<mat-option value="ADMIN_FEDE">Admin fédéral</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Nom entreprise</mat-label>
					<input matInput formControlName="companyName">
				</mat-form-field>
			</div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Nom contact</mat-label>
					<input matInput formControlName="name">
				</mat-form-field>
			</div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Actif</mat-label>
					<mat-select formControlName="archived" (selectionChange)="archivedChanged()">
						<mat-option [value]="0">Indifférent</mat-option>
						<mat-option value="true">Désactivé</mat-option>
						<mat-option value="false">Activé</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</form>
	</div>



	<p id="no-response" *ngIf="pageResponse?.totalElements === 0">Aucune utilisateur pour l'instant.</p>

	<div class="box" *ngIf="pageResponse?.totalElements > 0">

		<mat-paginator
			[length]="pageResponse?.totalElements"
			[pageSize]="pageResponse.size"
			[pageSizeOptions]="PAGINATION_OPTIONS"
			[pageIndex]="pageResponse?.number"
			(page)="pageChanged($event)">
		</mat-paginator>

		<div id="table-container">
			<table class="table is-striped"
				[ngClass]="{'is-max-desktop': userService.currentUser().role === ROLES.CLIENT}"
				*ngIf="pageResponse?.content.length > 0">
				<thead>
					<tr>
						<td>&nbsp;</td>
						<td>Rôle</td>
						<td>Nom utilisateur</td>
						<td>Nom entreprise</td>
						<td>Email</td>
						<td>N° téléphone</td>
						<td>Ville</td>
						<td *ngIf="form.get('role').value != ROLES.ADMIN_FEDE">Zone géographique</td>
						<td>Est actif</td>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let user of pageResponse.content">
						<td>
							<button class="button is-inverted is-primary"
								(click)="openUserModal(user.id)"
								tooltipStr="Accéder aux coordonnées de l'utilisateur"
								>
								Détails
							</button>
						</td>
						<td>
							<div class="role" [ngClass]="user.role">
								{{getRoleLitteral(user.role)}}
							</div>
						</td>
						<td>{{user.fullName}}</td>
						<td>
							<button
								*ngIf="user.role === ROLES.CLIENT"
								class="button is-inverted is-primary"
								tooltipStr="Voir les demandes de l'utilisateur"
								(click)="goClientPage(user.id, user.region)">
								{{user.companyName}}
							</button>
							<span *ngIf="user.role !== ROLES.CLIENT">
								{{user.companyName}}
							</span>
						</td>
						<td>{{user.email}}</td>
						<td>{{user.phone}}</td>
						<td>{{user.city}}</td>
						<td *ngIf="form.get('role').value != ROLES.ADMIN_FEDE">{{user.zones?.split(',').join(', ')}}</td>
						<td>
							<div class="status" [ngClass]="{'archived' : user.archived }">
								<span *ngIf="user.archived">Désactivé</span>
								<span *ngIf="!user.archived">Opérationnel</span>
							</div>
						</td>

					</tr>
				</tbody>
			</table>
		</div>
		<mat-paginator
			[length]="pageResponse?.totalElements"
			[pageSize]="pageResponse.size"
			[pageSizeOptions]="PAGINATION_OPTIONS"
			[pageIndex]="pageResponse?.number"
			(page)="pageChanged($event)">
		</mat-paginator>
	</div>

</section>
