import { Component } from '@angular/core';

@Component({
    selector: 'app-succes-reservation',
    templateUrl: './succes-reservation.component.html',
    styleUrl: './succes-reservation.component.scss',
    standalone: false
})

export class SuccesReservationComponent {

}
